import { ROUTES } from "@/constants";
import { IApplicationState } from "@/store-redux";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const useCheckPersonalized = () => {
  const localtion = useLocation();
  const navigate = useNavigate();
  const currentUser = useSelector((state: IApplicationState) => state.currentUser);
  const isCompletePersonalized = useMemo(() => {
    if (currentUser.data === null) return true;
    const personalization = currentUser.data?.personalization;
    return !_.isEmpty(personalization);
  }, [currentUser]);
  useEffect(() => {
    if (!isCompletePersonalized && localtion.pathname !== ROUTES.PERSONALIZED) {
      navigate(ROUTES.PERSONALIZED);
    }
  }, [isCompletePersonalized, localtion.pathname]);
  return {};
};
