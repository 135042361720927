import moment from "moment";
import _, { forEach } from "lodash";
import { useEffect, useState, useRef } from "react";
import { format } from "date-fns";
import { IItems } from "@/common";

export * from "./item";
export * from "./file";
export * from "./audio";

export function isNumeric(value: any) {
  return /^-{0,1}\d+$/.test(value);
}
export function isNumericInteger(value: any) {
  return /^[0-9]*$/.test(value);
}
export function isString(str: any) {
  let isStr = false;
  if (str && typeof str === "string") {
    isStr = true;
  }
  return isStr;
}
export const isEmailValid = function (str: any) {
  let emailValid = false;
  if (isString(str)) {
    if (str.match(/[a-z0-9_+\-.]+@[a-z0-9_\-.]+\.[a-z]+/i)) {
      emailValid = true;
    }
  }
  return emailValid;
};
export function buildFormData(formData: any, data: any, parentKey?: string) {
  if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
    if (Array.isArray(data)) {
      forEach(data, (item) => {
        formData.append(parentKey, item);
      });
    } else {
      Object.keys(data).forEach((key) => {
        const fieldKey = isNumeric(key) ? "[]" : `[${key}]`;
        buildFormData(formData, data[key], parentKey ? `${parentKey}${fieldKey}` : key);
      });
    }
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey, value);
  }
}

export function objectToFormData(data: any) {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}

export const formatSqlDate = (date: string, noTime?: boolean, shortYear?: boolean): string => {
  if (!date) return "";
  const formatDateTimeFull = "DD/MM/YYYY HH:mm";
  const formatDateFull = "DD/MM/YYYY";
  const formatDateTimeShort = "DD/MM/YY HH:mm";
  const formatDateShort = "DD/MM/YY";
  const dateTimeZone = moment.utc(date).local();
  const dateNormal = moment(date);
  if (!noTime) {
    if (isIsoDate(date)) {
      if (shortYear) {
        return dateTimeZone.format(formatDateTimeShort);
      }
      return dateTimeZone.format(formatDateTimeFull);
    }
    if (shortYear) {
      return dateNormal.format(formatDateTimeShort);
    }
    return dateNormal.format(formatDateTimeFull);
  }
  if (isIsoDate(date)) {
    if (shortYear) {
      return dateTimeZone.format(formatDateShort);
    }
    return dateTimeZone.format(formatDateFull);
  }
  if (shortYear) {
    return dateNormal.format(formatDateShort);
  }
  return dateNormal.format(formatDateFull);
};

export const useDebounce = (value: any, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

export const checkAccessParent = (accessible: any, mod: string) => {
  if (!accessible) {
    return true;
  }
  if (accessible[mod]) {
    if (!accessible[mod].length) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const checkAccess = (accessible: any, mod: string, action = "view") => {
  if (!accessible) {
    return true;
  }
  if (accessible[mod]) {
    if (_.indexOf(accessible[mod], action) === -1) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const usePrevious = (value: any) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  return ref.current;
};

export const timer = (seconds: number): string => {
  let min = "00";
  let sec = seconds < 10 ? "0" + String(seconds) : String(seconds);
  if (seconds >= 60) {
    const secs = seconds % 60;
    sec = secs < 10 ? "0" + String(secs) : String(secs);
    const mins = Math.floor(seconds / 60);
    min = mins < 10 ? "0" + String(mins) : String(mins);
  }
  return min + ":" + sec;
};

export const isNewerVersion = (oldVer: string, newVer: string) => {
  const oldParts = oldVer.split(".");
  const newParts = newVer.split(".");
  for (let i = 0; i < newParts.length; i++) {
    const a = ~~newParts[i]; // parse int
    const b = ~~oldParts[i]; // parse int
    if (a > b) return true;
    if (a < b) return false;
  }
  return false;
};

enum ETypeRange {
  day = "day",
  hour = "hour",
  minute = "minute",
  second = "second",
}

export const durationFromNow = (date: string, type: ETypeRange = ETypeRange.day): any => {
  const now = moment(new Date()); //todays date
  const duration = moment.duration(now.diff(date));
  switch (type) {
    case ETypeRange.day:
      return duration.asDays();
    case ETypeRange.hour:
      return duration.asHours();
    case ETypeRange.minute:
      return duration.asMinutes();
    case ETypeRange.second:
      return duration.asSeconds();
    default:
      break;
  }
};

export const isIsoDate = (str: string): boolean => {
  if (
    /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/.test(
      str
    )
  )
    return true;
  const d = new Date(str);
  if (d.toString() === "Invalid Date") return false;
  return d.toISOString() === str;
};

export const capitalizeFirstLetter = (s: string) => {
  return s && s[0].toUpperCase() + s.slice(1);
};

export const validateIpAddress = (ipaddress: string) => {
  const ipv46Regex =
    /(?:^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$)|(?:^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$)/gm;

  if (ipv46Regex.test(ipaddress)) {
    return true;
  }
  return false;
};

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

export const objectToQuery = (obj: any) => {
  const qs = Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join("&");

  return qs;
};

export function extractContent(s: string) {
  const span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
}

export function refinePrompt(input: string): string {
  return input
    .replace(/\u200B/g, "") // Remove all occurrences of the zero-width space (U+200B)
    .replace(/^\d+\.\s*/, "")
    .replace(/\?/, "")
    .replace("you", "they");
}

export function matchedLetterAnswer(input: string): string[] {
  const matchesLetters = input.match(/\(([^)]+)\)/g);
  return matchesLetters ?? [];
}

export function matchedNumberOfAspect(input: string): string[] {
  const matchesNumber = input.match(/\d+/);
  return matchesNumber ?? [];
}

export const genCategoryAspect = (findCategory: IItems, findAspect: IItems) => {
  const singleCategory = findCategory.name.replace(/<p>|-/g, "").split(" ")[1];
  const singleAspect = matchedNumberOfAspect(findAspect.name);
  return `${singleCategory}${singleAspect}`;
};

export function removedLetterAnswer(input: string): string[] {
  const removeLetters = input
    .replace(/\([^()]+\)/g, "")
    .trim()
    .split(/\s+/);
  return removeLetters;
}

export const getHeightWindow = () => {
  return window.innerHeight;
};

// Hàm này chuyển đổi thời gian của item thành múi giờ của trình duyệt và định dạng nó
export const convertToLocalTime = (createdAt: any) => {
  return format(new Date(createdAt), "HH:mm dd/MM/yyyy");
};

export const convertSecondToTime = (second: number) => {
  const minutes = Math.floor(second / 60);
  const seconds = Math.floor(second % 60);
  return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
};

export const removeLeadingNumbersAndPeriods = (str: string): string => {
  return str.replace(/^\d+\.\s*/, "");
};

export const searchVietnamese = (text: string, searchTerm: string): number => {
  // Convert both the text and search term to lowercase for case-insensitive search
  const lowerText: string = text.toLowerCase();
  const lowerSearchTerm: string = searchTerm.toLowerCase();

  // Use Unicode normalization to handle Vietnamese diacritics
  const normalizedText: string = lowerText.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  const normalizedSearchTerm: string = lowerSearchTerm.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  // Perform the search
  const index: number = normalizedText.indexOf(normalizedSearchTerm);

  // Return the index of the search term in the text
  return index;
};

export const isTablet = () => {
  const maxTabletWidth = 1024; // Maximum width for tablets
  const width = window.innerWidth;

  return width <= maxTabletWidth;
};

export const randomStringFromDatetime = () => {
  // Get current date and time
  const now = new Date();

  // Format datetime to string: YYYYMMDDHHMMSS
  const datetimeStr =
    now.getFullYear().toString() +
    (now.getMonth() + 1).toString().padStart(2, "0") +
    now.getDate().toString().padStart(2, "0") +
    now.getHours().toString().padStart(2, "0") +
    now.getMinutes().toString().padStart(2, "0") +
    now.getSeconds().toString().padStart(2, "0") +
    now.getMilliseconds().toString().padStart(3, "0");

  // Generate a random part to append
  const randomPart = Math.random().toString(36).substring(2, 8).toUpperCase();

  // Combine both parts
  return `${datetimeStr}_${randomPart}`;
};


export const cleanQuestion = (str: string) => {
  return extractContent(str)
    .replace(/^[A-Z]\d\s*-\s*\d+\.\s*/, "") // Remove existing prefix
    .replace(/^P\d+\s*-\s*/, ""); // Remove "P1 -", "P2 -", etc.
};
