import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import axios from "axios";
import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import moment from "moment";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import HomeworkPage from "../HomeworkPage";
const ClassLessonPage = ({ classId, students }) => {
  const [lessons, setLessons] = useState([]);
  const [isCreateLessonDialogOpen, setIsCreateLessonDialogOpen] = useState(false);
  const [newLessonTitle, setNewLessonTitle] = useState("");
  const [isAttendanceDialogOpen, setIsAttendanceDialogOpen] = useState(false);
  const [selectedLessonId, setSelectedLessonId] = useState(null);
  const [isHomeworkDialogOpen, setIsHomeworkDialogOpen] = useState(false);

  // Fetch lessons by classId
  const fetchLessons = async () => {
    try {
      //   const response = await axios.get(`/class/${classId}/lessons`);
      //   setLessons(response.data);
      const dataRes = await Requests.get(getApiUrl(ENDPOINTS.GET_LESSONS_BY_CLASS.replace(":id", classId)));
      if (Array.isArray(dataRes)) {
        setLessons(dataRes);
      }
    } catch (error) {
      console.error("Failed to fetch lessons:", error);
    }
  };

  useEffect(() => {
    fetchLessons();
  }, [classId]);

  // Handle creating a new lesson
  const handleCreateLesson = async () => {
    try {
      const apiUrl = getApiUrl(ENDPOINTS.CREATE_LESSON);
      const data = await Requests.post(apiUrl, {
        title: newLessonTitle,
        class: classId,
      });
      fetchLessons(); // Reload lessons after creating

      setIsCreateLessonDialogOpen(false);
      setNewLessonTitle("");
    } catch (error) {
      console.error("Failed to create lesson:", error);
    }
  };

  // Handle opening attendance popup
  const handleOpenAttendance = (lessonId) => {
    setSelectedLessonId(lessonId);
    setIsAttendanceDialogOpen(true);
  };

  const handleOpenHomework = (lessonId) => {
    setSelectedLessonId(lessonId);
    setIsHomeworkDialogOpen(true);
  };

  // Handle taking attendance
  const handleTakeAttendance = async (studentId: string, isPresent: boolean) => {
    try {
      const apiUrl = getApiUrl(ENDPOINTS.TAKE_ATTENDANCE.replace(":lessonId", selectedLessonId ?? ""));
      const data = await Requests.post(apiUrl, {
        studentId,
        present: isPresent,
      });
      fetchLessons();
      setIsAttendanceDialogOpen(false);
    } catch (error) {
      console.error("Failed to take attendance:", error);
    }
  };

  const selectedLesson = useMemo(() => {
    return lessons.find((item) => item._id === selectedLessonId);
  }, [selectedLessonId, lessons]);

  return (
    <Box p={3}>
      <Button
        variant="contained"
        color="primary"
        sx={{
          textTransform: "unset",
        }}
        onClick={() => setIsCreateLessonDialogOpen(true)}
      >
        Create Lesson
      </Button>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  fontWeight: "bold",
                  padding: "8px",
                },
              }}
            >
              <TableCell>Title</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Homework</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lessons.map((lessson, index) => (
              <TableRow
                key={index}
                sx={{
                  td: {
                    padding: "8px",
                  },
                }}
              >
                <TableCell>{lessson?.title ?? ""}</TableCell>
                <TableCell>{moment(lessson.date).format("lll")}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => handleOpenHomework(lessson._id)}
                    sx={{
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                    startIcon={<AutoStoriesIcon />}
                  >
                    Homework
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => handleOpenAttendance(lessson._id)}
                    sx={{
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Attendance
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={isCreateLessonDialogOpen} onClose={() => setIsCreateLessonDialogOpen(false)}>
        <DialogTitle>Create Lesson</DialogTitle>
        <DialogContent>
          <TextField
            label="Lesson Title"
            variant="outlined"
            value={newLessonTitle}
            onChange={(e) => setNewLessonTitle(e.target.value)}
            fullWidth
            sx={{
              margin: "10px 0",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsCreateLessonDialogOpen(false)}
            color="primary"
            sx={{
              textTransform: "unset",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreateLesson}
            color="primary"
            variant="contained"
            sx={{
              textTransform: "unset",
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isAttendanceDialogOpen} onClose={() => setIsAttendanceDialogOpen(false)}>
        <DialogTitle>
          Take Attendance <strong>{selectedLesson?.title ?? ""}</strong>
        </DialogTitle>
        <DialogContent>
          {students.map((student) => (
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        fontWeight: "bold",
                        padding: "8px",
                      },
                    }}
                  >
                    <TableCell>Username</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students.map((item, index) => {
                    const studentAttendance = selectedLesson?.attendance?.find(
                      (item) => item.student._id == item.student?._id
                    );
                    console.log("studentAttendance", studentAttendance);
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          ...(studentAttendance
                            ? studentAttendance.present
                              ? {
                                  backgroundColor: "#C0EBA6",
                                }
                              : {
                                  backgroundColor: "#FFAAAA",
                                }
                            : {}),
                          "& td": {
                            padding: "8px",
                          },
                        }}
                      >
                        <TableCell>{item.student?.username ?? ""}</TableCell>
                        <TableCell>{item.student?.email ?? ""}</TableCell>
                        <TableCell>
                          {studentAttendance ? (
                            <Box>
                              {studentAttendance.present ? (
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  Present
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  Absent
                                </Typography>
                              )}
                            </Box>
                          ) : (
                            <Box>
                              <Button
                                onClick={() => handleTakeAttendance(item.student._id, true)}
                                color="primary"
                                sx={{
                                  textTransform: "none",
                                  fontWeight: "bold",
                                }}
                              >
                                Present
                              </Button>
                              <Button
                                onClick={() => handleTakeAttendance(item.student._id, false)}
                                color="secondary"
                                sx={{
                                  textTransform: "none",
                                  fontWeight: "bold",
                                }}
                              >
                                Absent
                              </Button>
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsAttendanceDialogOpen(false)}
            sx={{
              textTransform: "none",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isHomeworkDialogOpen} onClose={() => setIsHomeworkDialogOpen(false)}>
        <DialogTitle>
          Homework <strong>{selectedLesson?.title ?? ""}</strong>
        </DialogTitle>
        <DialogContent>
          <HomeworkPage lessonId={selectedLessonId} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsHomeworkDialogOpen(false)}
            sx={{
              textTransform: "none",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ClassLessonPage;
