import { BrowserRouter, Routes, Route } from "react-router-dom";
import ChallengePage from "@/pages/ChallengePage";
import ProfilePage from "@/pages/ProfilePage";
import { PrivateRoute } from "./PrivateRoute";
import DefaultLayout from "../components/Layout/DefaultLayout";
import { ROUTES } from "../constants";
import UsersPage from "@/pages/CMS/UsersPage";
import CMSLayout from "../components/Layout/CMSLayout";
import TopicQuestionsPage from "@/pages/CMS/TopicsQuestionsPage";
import Login from "@/pages/LoginPage";
import NotFoundPage from "@/pages/NotFoundPage";
import CategoryAspectPage from "@/pages/CMS/CategoryAspectPage";
import QuestionIdeaPage from "@/pages/CMS/QuestionIdeaPage";
import IdeaPage from "@/pages/LearnPage/components/IdeaPage";
import ChallengeCMSPage from "@/pages/CMS/ChallengeCMSPage";
import AnswerPage from "@/pages/LearnPage/components/AnswerPage";
import WordbankPage from "@/pages/WordbankPage";
import WordbookPage from "@/pages/WordbookPage";
import AllAspectPage from "@/pages/CMS/AllAspectPage";
import PersonalizedCMSPage from "@/pages/CMS/PersonalizedCMSPage";
import StructureCMSPage from "@/pages/CMS/StructureCMSPage";
import AICallStatisCMSPage from "@/pages/CMS/AICallStatisCMSPage";
import GrammarCMSPage from "@/pages/CMS/GrammarCMSPage";
import LLMCMSPage from "@/pages/CMS/LLMCMSPage";
import DictReportCMSPage from "@/pages/CMS/DictReportCMSPage";
import LearnPage from "@/pages/LearnPage";
import GroupPart2Page from "@/pages/LearnPage/components/GroupPart2Page";
import QuestionPart2CMSPage from "@/pages/CMS/QuestionPart2CMSPage";
import QuestionPart2Page from "@/pages/LearnPage/components/QuestionPart2Page";
import QuestionTypeCMSPage from "@/pages/CMS/QuestionTypeCMSPage";
import QuestionTypePage from "@/pages/LearnPage/components/QuestionTypePage";
import QuestionTypeListQuestionPage from "@/pages/LearnPage/components/QuestionTypeListQuestionPage";
import QuestionTypeByCatPage from "@/pages/LearnPage/components/QuestionTypeByCatPage";
import GuidedQuestionPage from "@/pages/LearnPage/components/GuidedQuestionPage";
import TopicQuestionTypePage from "@/pages/LearnPage/components/QuestionPart1&3Page";
import LearnQuestionPage from "@/pages/LearnPage/components/LearnQuestionPage";
import Part2Page from "@/pages/LearnPage/components/Part2Page";
import ClassPage from "@/pages/Teacher/ClassPage";
import TeacherLayout from "@/components/Layout/TeacherLayout";
import ClassDetailPage from "@/pages/Teacher/ClassDetailPage";
import MyClassPage from "@/pages/Student/MyClassPage";
import LearnVocaCMSPage from "@/pages/CMS/LearnVocaCMSPage";
import LearnVocaByQuestion from "@/pages/CMS/LearnVocaCMSPage/LearnVocaByQuestion";

const routerConfig = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTES.USERS,
    element: (
      <PrivateRoute>
        <CMSLayout>
          <UsersPage />
        </CMSLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.TOPIC_QUESTION,
    element: (
      <PrivateRoute>
        <CMSLayout>
          <TopicQuestionsPage />
        </CMSLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.QUESTION_IDEA,
    element: (
      <PrivateRoute>
        <CMSLayout>
          <QuestionIdeaPage />
        </CMSLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.CATEGORY_ASPECT,
    element: (
      <PrivateRoute>
        <CMSLayout>
          <CategoryAspectPage />
        </CMSLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.CHALLENGE_CMS,
    element: (
      <PrivateRoute>
        <CMSLayout>
          <ChallengeCMSPage />
        </CMSLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.PERSONALIZED_CMS,
    element: (
      <PrivateRoute>
        <CMSLayout>
          <PersonalizedCMSPage />
        </CMSLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.STRUCTURE_CMS,
    element: (
      <PrivateRoute>
        <CMSLayout>
          <StructureCMSPage />
        </CMSLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.GRAMMAR_CMS,
    element: (
      <PrivateRoute>
        <CMSLayout>
          <GrammarCMSPage />
        </CMSLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.AI_CALL_STATIS_CMS,
    element: (
      <PrivateRoute>
        <CMSLayout>
          <AICallStatisCMSPage />
        </CMSLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.LLM_ENERGY_CMS,
    element: (
      <PrivateRoute>
        <CMSLayout>
          <LLMCMSPage />
        </CMSLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.DICT_REPORT_CMS,
    element: (
      <PrivateRoute>
        <CMSLayout>
          <DictReportCMSPage />
        </CMSLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.QUESTION_TYPE_CMS,
    element: (
      <PrivateRoute>
        <CMSLayout>
          <QuestionTypeCMSPage />
        </CMSLayout>
      </PrivateRoute>
    ),
  },
  //   {
  //     path: ROUTES.LEARN,
  //     element: (
  //       <PrivateRoute>
  //         <DefaultLayout>
  //           <LearnPage />
  //         </DefaultLayout>
  //       </PrivateRoute>
  //     ),
  //   },
  {
    path: ROUTES.CHALLENGE,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <ChallengePage />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.PROFILE,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <ProfilePage />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  // PATH LEARN PAGE
  {
    path: ROUTES.LEARN,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <LearnPage />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.LEARN_IDEA,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <IdeaPage />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.LEARN_GROUP_PART2,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <GroupPart2Page />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.LEARN_QUESTION_PART2,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <QuestionPart2Page />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.LEARN_GUIDED_QUESTION_PART2,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <GuidedQuestionPage />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.LEARN_QUESTION_TYPE,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <QuestionTypePage />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.LEARN_QUESTION_TYPE_BY_CAT,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <QuestionTypeByCatPage />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.LEARN_QUESTION_TYPE_LIST_QUESTION,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <QuestionTypeListQuestionPage />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.ANSWER,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <AnswerPage />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.WORDBANK,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <WordbankPage />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.WORDBOOK,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <WordbookPage />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.LEARN_TOPIC_QUESTION,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <TopicQuestionTypePage />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.ASPECT_ALL_CMS,
    element: (
      <PrivateRoute>
        <CMSLayout>
          <AllAspectPage />
        </CMSLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.QUESTION_PART2,
    element: (
      <PrivateRoute>
        <CMSLayout>
          <QuestionPart2CMSPage />
        </CMSLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.CMS_LEARN_VOCA,
    element: (
      <PrivateRoute>
        <CMSLayout>
          <LearnVocaCMSPage />
        </CMSLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.CMS_LEARN_VOCA_QUESTION,
    element: (
      <PrivateRoute>
        <CMSLayout>
          <LearnVocaByQuestion />
        </CMSLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.LEARN_QUESTION_PAGE,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <LearnQuestionPage />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.ANSWER_QUESTION,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <AnswerPage />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.LEARN_PART_2,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <Part2Page />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.TEACHER_CLASS,
    element: (
      <PrivateRoute>
        <TeacherLayout>
          <ClassPage />
        </TeacherLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.TEACHER_CLASS_DETAIL,
    element: (
      <PrivateRoute>
        <TeacherLayout>
          <ClassDetailPage />
        </TeacherLayout>
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.STUDENT_MY_CLASS,
    element: (
      <PrivateRoute>
        <DefaultLayout>
          <MyClassPage />
        </DefaultLayout>
      </PrivateRoute>
    ),
  },
];

export function AllPages() {
  return (
    <BrowserRouter>
      <Routes>
        {routerConfig.map((route, index) => {
          return <Route key={index} path={route.path} element={route.element} />;
        })}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
