import { ExerciseResults } from "../types/exercise.types";

const ResultsSummary: React.FC<{ results: ExerciseResults; onTryAgain: () => void }> = ({ results, onTryAgain }) => {
  const percentage = Math.round((results.totalScore / results.totalQuestions) * 100);

  return (
    <div className="results-summary">
      <h2>Final Results</h2>

      <div className="score-card">
        <div className="total-score">
          <div className="score-circle">
            <span className="percentage">{percentage}%</span>
          </div>
          <p>
            Total Score: {results.totalScore} / {results.totalQuestions}
          </p>
        </div>

        <div className="section-scores">
          <div className="score-item">
            <h3>Matching</h3>
            <p>
              {results.matching.score} / {results.matching.total}
            </p>
          </div>
          <div className="score-item">
            <h3>Fill in the Blanks</h3>
            <p>
              {results.fillBlanks.score} / {results.fillBlanks.total}
            </p>
          </div>
          <div className="score-item">
            <h3>Multiple Choice</h3>
            <p>
              {results.multipleChoice.score} / {results.multipleChoice.total}
            </p>
          </div>
          <div className="score-item">
            <h3>Verb Forms</h3>
            <p>
              {results.verbForms.score} / {results.verbForms.total}
            </p>
          </div>
          <div className="score-item">
            <h3>Sentence Formation</h3>
            <p>
              {results.sentenceFormation.score} / {results.sentenceFormation.total}
            </p>
          </div>
          <div className="score-item">
            <h3>Fill in blank grammar</h3>
            <p>
              {results.grammarBlanks.score} / {results.grammarBlanks.total}
            </p>
          </div>
        </div>

        <div className="feedback-message">
          {percentage === 100 && <p className="perfect">Perfect score! Outstanding work! 🎉</p>}
          {percentage >= 80 && percentage < 100 && <p className="excellent">Excellent work! Keep it up! 👏</p>}
          {percentage >= 60 && percentage < 80 && <p className="good">Good effort! Room for improvement. 👍</p>}
          {percentage < 60 && <p className="needs-practice">Keep practicing! You'll get better! 💪</p>}
        </div>

        <button onClick={onTryAgain} className="try-again-btn">
          Try Again
        </button>
      </div>
    </div>
  );
};

export default ResultsSummary;
