import React, { useState } from "react";
import { IUserAnswers, VerbFormsExerciseData } from "../types/exercise.types";

interface Props {
  data: VerbFormsExerciseData;
  isSubmitted?: boolean;
  setUserAnswers: React.Dispatch<React.SetStateAction<IUserAnswers>>;
}

const VerbFormsExercise: React.FC<Props> = ({ data, isSubmitted, setUserAnswers }) => {
  const [answers, setAnswers] = useState<Record<number, string>>({});

  const handleChange = (index: number, value: string): void => {
    const newAnswers = {
      ...answers,
      [index]: value,
    };
    setAnswers(newAnswers);
    setUserAnswers((prev) => ({
      ...prev,
      verbForms: {
        ...prev.verbForms,
        ...newAnswers,
      },
    }));
  };

  return (
    <div className="exercise-section fill-blanks">
      <h2>Verb Forms Exercise</h2>
      <p>{data.instructions}</p>

      <div className="sentences">
        {data.sentences.map((sentence, index) => (
          <div key={index} className="sentence">
            {sentence.sentence.split("(")[0]}
            <input
              type="text"
              value={answers[index] || ""}
              onChange={(e) => handleChange(index, e.target.value)}
              style={{
                backgroundColor: isSubmitted
                  ? answers[index]?.toLowerCase() === sentence.answer.toLowerCase()
                    ? "#e8f5e9"
                    : "#ffebee"
                  : "white",
              }}
              disabled={isSubmitted}
            />
            {sentence.sentence.split(")")[1]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerbFormsExercise;
