import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Input,
  CircularProgress,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import { useDispatch } from "react-redux";
import { showMessage } from "@/store-redux";
interface SubmitHomeworkProps {
  lessonId: string | null;
  onRefresh: () => {};
}
const SubmitHomework = ({ lessonId, onRefresh }: SubmitHomeworkProps) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null); // Store file preview URL
  const [text, setText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle file upload change with preview generation
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // Generate preview if the file is an image or video
    if (selectedFile && (selectedFile.type.startsWith("image/") || selectedFile.type.startsWith("video/"))) {
      const previewUrl = URL.createObjectURL(selectedFile);
      setFilePreview(previewUrl);
    } else {
      setFilePreview(null); // Clear preview if not an image/video
    }
  };

  // Handle text input for homework
  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  // Handle homework submission
  const handleSubmit = async () => {
    if (!file && !text) {
      alert("Please upload a file or provide a text response!");
      return;
    }

    setIsSubmitting(true);

    try {
      const urlUploadFile = getApiUrl(ENDPOINTS.SUBMIT_HOMEWORK);

      await Requests.post(
        urlUploadFile,
        {
          file,
          text,
          lessonId,
        },
        true,
        false
      );
      dispatch(showMessage("Homework submitted successfully!", "success"));
      if (onRefresh) onRefresh();
      setFile(null);
      setFilePreview(null);
      setText("");
    } catch (error) {
      dispatch(showMessage("Failed to submit homework!", "error"));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box>
      {/* Homework File Upload with Preview */}
      <Input
        type="file"
        onChange={handleFileChange}
        fullWidth
        sx={{
          mb: 2,
          "&::before": {
            display: "none",
          },
          "&::after": {
            display: "none",
          },
        }}
      />

      {/* Preview Section */}
      {file && (
        <Box sx={{ mt: 2 }}>
          {filePreview ? (
            // If file is an image or video, display a preview
            <Card sx={{ maxWidth: 345, mb: 2 }}>
              <CardMedia
                component={file.type.startsWith("image/") ? "img" : "video"}
                height="200"
                image={filePreview}
                alt="File preview"
                controls={file.type.startsWith("video/")}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
                </Typography>
              </CardContent>
            </Card>
          ) : (
            // If file is not an image or video, display its name
            <Typography variant="body1" gutterBottom>
              {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
            </Typography>
          )}
        </Box>
      )}

      {/* Homework Text Input */}
      <TextField
        label="Text Response"
        variant="outlined"
        multiline
        rows={4}
        value={text}
        onChange={handleTextChange}
        fullWidth
        sx={{ mb: 2 }}
      />

      {/* Submit Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isSubmitting}
        sx={{
          textTransform: "none",
        }}
        startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
      >
        {isSubmitting ? "Submitting..." : "Submit Homework"}
      </Button>
    </Box>
  );
};

export default SubmitHomework;
