import NoDataComponent from "@/components/NoDataComponent";
import { genCategoryAspect } from "@/helpers";
import { addIntoCart, ETypeItem, getListIdeas, IApplicationState, IItems, removeIntoCart } from "@/store-redux";
import { Box, Grid, Skeleton, Typography, TypographyProps, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
interface ListSuggestionAspectProps {
  aspectId: string;
}
const ListSuggestionAspect = ({ aspectId }: ListSuggestionAspectProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { questionId } = useParams();
  const listMyIdeaPath = useSelector((state: IApplicationState) => state.myIdeaPath);
  const listIdea = useSelector((state: IApplicationState) => state.getListIdeas);
  const [categoryAndAspect, setCategoryAndAspect] = useState<string>();
  const listAspects = useSelector((state: IApplicationState) => state.listAspects);
  const listCategories = useSelector((state: IApplicationState) => state.listCategories);

  useEffect(() => {
    if (aspectId)
      dispatch(
        getListIdeas({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          sortBy: "createdAt",
          parentId: questionId,
          aspectId: aspectId,
          checkMatchScore: true,
        })
      );
  }, [aspectId]);

  const handleAddOrRemoveIntoCart = (idea: IItems) => {
    if (listMyIdeaPath.data.find((item) => item._id === idea._id)) {
      dispatch(removeIntoCart(idea));
    } else {
      const infoIdea = { ...idea, locationIdea: categoryAndAspect };
      dispatch(addIntoCart(infoIdea));
    }
  };
  const getColorByScore = (score: number): string => {
    return score >= 80 ? "#97BE5A" : score >= 60 ? "#FFAF45" : score >= 40 ? "#FB6D48" : "#FF204E";
  };

  const getBgColorByScore = (score: number): string => {
    return score >= 80
      ? "rgba(151, 190, 90, 0.2)"
      : score >= 60
      ? "rgba(255, 175, 69, 0.2)"
      : score >= 40
      ? "rgba(251, 109, 72, 0.2)"
      : "rgba(255, 32, 78, 0.2)";
  };
  const listIdeas = listIdea.data
    ?.filter((idea) => idea.type === ETypeItem["idea.main"])
    .sort((a, b) => b.score - a.score);

  useEffect(() => {
    let singleNameTag = "☆";
    const findAspect = listAspects?.data?.find((item) => item._id === aspectId);
    if (findAspect) {
      const findCategory = listCategories?.data?.find((item) => item._id === findAspect.parentId);
      if (findCategory) {
        singleNameTag = genCategoryAspect(findCategory, findAspect);
      }
    }
    setCategoryAndAspect(singleNameTag);
  }, [listIdeas]);
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: "14px",
          marginTop: "8px",
          marginBottom: "4px",
        }}
      >
        Suggestions for you
      </Typography>
      <Box className="box-container">
        {listIdea.isFetching ? (
          <Grid item xs>
            <div>
              {(["h1", "h1", "h1", "h1", "h1"] as readonly TypographyProps["variant"][]).map((variant, idx) => (
                <Typography component="div" key={idx} variant={variant}>
                  <Skeleton />
                </Typography>
              ))}
            </div>
          </Grid>
        ) : (
          listIdeas?.map((idea) => {
            const isChecked = listMyIdeaPath.data.some((item) => idea._id === item._id);
            return (
              <Box
                onClick={() => handleAddOrRemoveIntoCart(idea)}
                key={idea._id}
                sx={{
                  display: "flex",
                  padding: "10px",
                  cursor: "pointer",
                  borderRadius: "8px",
                  marginBottom: "5px",
                  ...(isChecked ? { backgroundColor: "#F3F3FF" } : {}),
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "40px",
                  }}
                >
                  {isChecked ? (
                    <CheckBoxIcon
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <CheckBoxOutlineBlankIcon
                      sx={{
                        color: "#D4D4D4",
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "left",
                      fontSize: "15px!important",
                      fontWeight: "bold",
                      "& *": {
                        fontSize: "15px!important",
                      },
                    }}
                    className="title"
                    dangerouslySetInnerHTML={{ __html: idea.name?.replace(/\d+\.\s*&nbsp;/, "") || "" }}
                  />
                  <Box
                    sx={{
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                    className="description"
                    dangerouslySetInnerHTML={{ __html: idea.description || "" }}
                  />
                </Box>
                <Box
                  sx={{
                    width: 60,
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-end",
                  }}
                >
                  {idea.score !== undefined && (
                    <Box
                      sx={{
                        fontWeight: "700",
                        color: getColorByScore(idea.score),
                        backgroundColor: getBgColorByScore(idea.score),
                        padding: "0 8px",
                        borderRadius: "5px",
                      }}
                    >
                      {idea.score}%
                    </Box>
                  )}
                </Box>
              </Box>
            );
          })
        )}
        {listIdea.data?.length === 0 && !listIdea.isFetching ? <NoDataComponent /> : null}
      </Box>
    </Box>
  );
};
export default ListSuggestionAspect;
