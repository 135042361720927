import { ECurrentStep, IItems, IPracticeEvaluateOutput } from "@/common";
import DetailPractice from "@/components/DetailPractice";
import { LLMTypes } from "@/constants";
import { convertSecondToTime, extractContent } from "@/helpers";
import {
  useGetListEnergyPrice,
  useGetQuotaPractice,
  usePracticeEvaluate,
  usePracticeTranscribe,
  useUploadFilePractice,
} from "@/services-react-query/queries";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StarIcon from "@mui/icons-material/Star";
import { Box, Button, Dialog, IconButton, LinearProgress, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import CloseIcon from "@mui/icons-material/Close";

interface ITestingSpeakingModalProps {
  openPractice: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  dataQuestion:
    | IItems
    | {
        name: string;
        _id: string;
      };
}

const CONTENT_STEP: {
  [key: string]: {
    description: string;
    iconButton: string;
  };
} = {
  START_RECORDING_2: {
    description: "Press the button to record your answer using requirement above",
    iconButton: "/svgs/microphone.svg",
  },
  RECORDING_3: {
    description: "Recording... press again to stop recording",
    iconButton: "/svgs/square.svg",
  },
  SUBMIT_4: {
    description: "Press the button to submit your answer",
    iconButton: "/svgs/upload.svg",
  },
};

function TestingSpeakingModal({ openPractice, onClose, dataQuestion }: ITestingSpeakingModalProps) {
  const dataTemp = {
    gptEvaluates: [
      {
        criterium: "Fluency and coherence",
        band: 6,
        explanation:
          "The response is generally fluent with some hesitation and repetition. Ideas are coherently connected using basic linking words like 'so' and 'but'.",
        suggestion:
          "To improve fluency and coherence, practice speaking more frequently and try to use a wider range of linking words and phrases to connect your ideas more smoothly.",
      },
      {
        criterium: "Lexical resource",
        band: 5,
        explanation:
          "The vocabulary used is adequate for the task, but there is some repetition and a lack of more sophisticated terms related to the topic of specialization and university studies.",
        suggestion:
          "Expand your vocabulary by reading academic texts and articles related to your field of interest. Learn and practice using more specific terms related to business, management, and finance.",
      },
      {
        criterium: "Grammatical range and accuracy",
        band: 6,
        explanation:
          "The response demonstrates a mix of simple and complex sentence structures, with some errors in grammar and word choice (e.g., 'gonna' instead of 'going to').",
        suggestion:
          "Focus on improving your grammatical accuracy by reviewing verb tenses, subject-verb agreement, and word choice. Practice constructing more complex sentences to showcase your grammatical range.",
      },
      {
        criterium: "Pronunciation",
        band: 6,
        explanation:
          "The pronunciation is generally clear and intelligible, with some influence of the speaker's first language on intonation and stress patterns.",
        suggestion:
          "Practice pronouncing words and phrases related to your field of study, paying attention to word stress and intonation. Listen to native speakers and try to imitate their pronunciation and rhythm.",
      },
    ],
    attempt: 0,
    voiceTranscript:
      "Currently, I'm a freshman at university, so I'm going to spend my 1st year learning the basics of business theory. To be honest, I haven't decided which major I'm gonna choose. But right now, I'm thinking about pursuing business management of finance.",
  };
  const [dataEvaluate, setDataEvaluate] = useState<IPracticeEvaluateOutput | null>(null);
  const [currentStep, setCurrentStep] = useState<ECurrentStep>(ECurrentStep.START_RECORDING_2);
  const { data: listEnergyPrice } = useGetListEnergyPrice({});
  const theme = useTheme();

  const onBack = () => {
    if (currentStep === ECurrentStep.RECORDING_3) {
      setCurrentStep(ECurrentStep.START_RECORDING_2);
    } else if (currentStep === ECurrentStep.SUBMIT_4) {
      setCurrentStep(ECurrentStep.RECORDING_3);
    } else if (currentStep === ECurrentStep.RESULT_5) {
      setCurrentStep(ECurrentStep.SUBMIT_4);
    } else {
      handleClose();
    }
  };
  const [audioRecord, setAudioRecord] = useState<any>();
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );

  const { data: dataQuotaPractice } = useGetQuotaPractice({});

  const { mutate: uploadFileAudio, isLoading: loadingUploadFile } = useUploadFilePractice({
    onSuccess(data) {
      if (data?._id) {
        postTranscribe(data?._id);
      }
    },
  });

  const { mutate: postTranscribe, isLoading: loadingTranscribe } = usePracticeTranscribe({
    onSuccess(data) {
      if (data?._id) {
        postEvaluate(data?._id);
      }
    },
  });

  const { mutate: postEvaluate, isLoading: loadingEvaluate } = usePracticeEvaluate({
    onSuccess(values) {
      setDataEvaluate(values);
      setCurrentStep(ECurrentStep.RESULT_5);
      // refetchQuota();
    },
  });

  const addAudioElement = (blob: Blob) => {
    const url = URL.createObjectURL(blob);
    console.log("url", url)
    setAudioRecord(url);
  };

  const handleSendAudioAnswer = async () => {
    const blob = await (await fetch(audioRecord)).blob();
    const timeStamp = new Date(Date.now()).toISOString().replace(/:/g, "-").replace(/\./g, "-");
    const file = new File([blob], `${timeStamp}.webm`, { type: "audio/webm" });

    uploadFileAudio({
      questionId: dataQuestion?._id ?? "",
      voiceFile: file,
    });
  };

  useEffect(() => {
    if (recorderControls.recordingTime > 120) {
      recorderControls.stopRecording();
      setCurrentStep(ECurrentStep.SUBMIT_4);
    }
  }, [recorderControls, recorderControls.recordingTime]);

  useEffect(() => {
    if (openPractice) {
      setCurrentStep(ECurrentStep.START_RECORDING_2);
    }
  }, [openPractice]);

  const handleClose = () => {
    recorderControls?.stopRecording();
    onClose(false);
    setCurrentStep(ECurrentStep.START_RECORDING_2);
  };
  return (
    <Dialog
      open={openPractice}
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: "100vw",
          minHeight: "100vh",
          padding: "32px",
        },
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          right: "10px",
          top: "10px",
        }}
        onClick={() => handleClose()}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "24px" }}>
        <Box
          dangerouslySetInnerHTML={{
            __html: extractContent(dataQuestion?.name ?? "") ?? "",
          }}
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
          }}
        />
        <Box sx={{}}>
          <Button
            variant="outlined"
            onClick={onBack}
            sx={{
              minWidth: "68px",
              height: "40px",
              padding: "6px 16px",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: 700,
              textTransform: "none",
              "& svg": {
                marginRight: "4px",
              },
            }}
          >
            <ArrowBackIcon />
            Back
          </Button>
        </Box>
      </Box>
      <Box
        sx={() => ({
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          overflow: "auto",
          position: "relative",
          flex: "1",
          "& .box-content": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: "1",
            "& img": {
              width: "30px",
            },
          },
          "& h2": {
            textAlign: "center",
            fontSize: "28px",
            fontWeight: "500",
            marginTop: "64px",
          },
          "& h3": {
            textAlign: "center",
            marginTop: "20px",
            color: "#6A6969",
          },
          "& button.icon-submit": {
            marginBlock: "20px",
            height: "88px",
            width: "88px",
            backgroundColor: theme.palette.primary.main,
            boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
          },
          "& .btn-close": {
            position: "absolute",
            top: "20px",
            right: "20px",
          },
        })}
      >
        {/* <IconButton className="btn-close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        {currentStep !== ECurrentStep.START_RECORDING_2 && onBack && (
          <Box
            sx={{
              position: "absolute",
              top: "30px",
              left: "20px",
              "& button": {
                height: "40px",
              },
            }}
          >
            <Button onClick={onBack} variant="contained" color="secondary">
              Back
            </Button>
          </Box>
        )} */}
        {/* <Typography component={"h2"}>{extractContent(dataQuestion?.name ?? "")}</Typography> */}
        {/* {currentStep === ECurrentStep.START_RECORDING_2 && dataQuotaPractice?.week_max ? (
          <Typography component={"span"} sx={{ textAlign: "center" }}>
            (Your remaining number in this week:{" "}
            <Typography component={"span"} sx={{ fontWeight: "bold" }}>
              {dataQuotaPractice?.week_max - dataQuotaPractice?.week_count}
            </Typography>
            )
          </Typography>
        ) : null} */}
        <Box sx={{ display: "none" }}>
          <AudioRecorder onRecordingComplete={(blob) => addAudioElement(blob)} recorderControls={recorderControls} />
        </Box>
        {dataQuotaPractice?.week_max - dataQuotaPractice?.week_count === 0 ? (
          <Box>
            <Typography component={"h2"} sx={{ color: "red" }}>
              Your quota has been exceeded
            </Typography>
          </Box>
        ) : (
          <Box className="box-content">
            {/* <img src="/images/banner-practice.png" width={"100%"} /> */}
            {currentStep === ECurrentStep.START_RECORDING_2 ? (
              <IconButton
                onClick={() => {
                  recorderControls.startRecording();
                  setCurrentStep(ECurrentStep.RECORDING_3);
                }}
                className="icon-submit"
              >
                <img src={CONTENT_STEP?.[`${currentStep}`]?.iconButton} />
              </IconButton>
            ) : null}
            {currentStep === ECurrentStep.RECORDING_3 ? (
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography component={"span"} sx={{ fontSize: "30px" }}>
                  {convertSecondToTime(recorderControls.recordingTime)}{" "}
                </Typography>
                {recorderControls.recordingTime > 100 ? (
                  <Typography component={"span"} sx={{ color: "red", textAlign: "center", paddingInline: "16px" }}>
                    You have {120 - recorderControls.recordingTime} seconds left to answer the question
                  </Typography>
                ) : null}
                <IconButton
                  onClick={() => {
                    recorderControls.stopRecording();
                    setCurrentStep(ECurrentStep.SUBMIT_4);
                  }}
                  className="icon-submit"
                >
                  <img src={CONTENT_STEP?.[`${currentStep}`]?.iconButton} />
                </IconButton>
              </Box>
            ) : null}
            {currentStep === ECurrentStep.SUBMIT_4 ? (
              loadingUploadFile || loadingTranscribe || loadingEvaluate ? (
                <Box
                  sx={{
                    marginTop: "40px",
                    width: "80%",
                  }}
                >
                  <Typography
                    sx={{
                      marginBottom: "10px",
                    }}
                  >
                    {loadingUploadFile ? (
                      "The audio is being processing..."
                    ) : loadingTranscribe ? (
                      <Typography
                        sx={{
                          display: "flex",
                        }}
                      >
                        Transcribing...
                        <Typography sx={{ fontWeight: "bold" }}>
                          ({listEnergyPrice?.types?.[LLMTypes.transcribe]}{" "}
                        </Typography>
                        <StarIcon
                          sx={{
                            marginLeft: "2px",
                            fontSize: "18px",
                          }}
                          htmlColor="#fff31b"
                        />
                        )
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          display: "flex",
                        }}
                      >
                        Evaluating...
                        <Typography sx={{ fontWeight: "bold" }}>
                          ({listEnergyPrice?.types?.[LLMTypes.transcribe]}{" "}
                        </Typography>
                        <StarIcon
                          sx={{
                            marginLeft: "2px",
                            fontSize: "18px",
                          }}
                          htmlColor="#fff31b"
                        />
                        )
                      </Typography>
                    )}
                  </Typography>
                  <LinearProgress
                    color="success"
                    sx={{}}
                    valueBuffer={loadingUploadFile ? 30 : loadingTranscribe ? 70 : loadingEvaluate ? 100 : 0}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "10px",
                    "& video": {
                      marginTop: "-60px",
                    },
                  }}
                >
                  <video src={audioRecord} controls />

                  <IconButton
                    onClick={() => {
                      handleSendAudioAnswer();
                    }}
                    className="icon-submit"
                  >
                    <img src={CONTENT_STEP?.[`${currentStep}`]?.iconButton} />
                  </IconButton>
                </Box>
              )
            ) : null}
            {currentStep === ECurrentStep.RESULT_5 ? (
              <Result
                nextStep={() => {
                  setCurrentStep(ECurrentStep.START_RECORDING_2);
                }}
                dataEvaluate={dataEvaluate}
              />
            ) : null}
            <Typography component={"h3"}>{CONTENT_STEP?.[`${currentStep}`]?.description}</Typography>
          </Box>
        )}
      </Box>
    </Dialog>
  );
}

interface IResultProps {
  nextStep: () => void;
  dataEvaluate: IPracticeEvaluateOutput | null;
}

function Result({ nextStep, dataEvaluate }: IResultProps) {
  return (
    <Box
      sx={() => ({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginInline: "32px",
        "& h2": {
          textAlign: "center",
          fontSize: "30px",
          marginTop: 0,
        },
      })}
    >
      {dataEvaluate?.voiceTranscript && (
        <DetailPractice
          voiceTranscript={dataEvaluate.voiceTranscript}
          gptEvaluates={dataEvaluate.gptEvaluates}
          isShowVoice
          // voicePath={dataEvaluate.voicePath}
        />
      )}
      <Box
        sx={() => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
          marginTop: "32px",
          marginBottom: "16px",
          width: "100%",
          "& button": {
            borderRadius: "8px",
            height: 38,
            fontSize: "18px",
            fontWeight: "400",
            textTransform: "none",
            "&.active": {
              fontWeight: "bold",
            },
          },
        })}
      >
        <Button variant="contained" color="primary" className={"active"} onClick={nextStep}>
          Try again
        </Button>
      </Box>
    </Box>
  );
}

export default TestingSpeakingModal;
