import { LLMTypes } from "@/constants";
import { useGetListEnergyPrice } from "@/services-react-query/queries";
import { ENDPOINTS, getApiUrl, IApplicationState, IGrammarAnalysis, Requests, showMessage } from "@/store-redux";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import CloseIcon from "@mui/icons-material/Close";
import BoltIcon from "@mui/icons-material/Bolt";
import {
  Box,
  Button,
  Card,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import clsx from "clsx";
import { isArray } from "lodash";
import { UseMutateFunction } from "react-query";
import { useDispatch, useSelector } from "react-redux";
interface IGrammarAnalysisModalProps {
  grammarAnalysis: UseMutateFunction<any, unknown, IGrammarAnalysis, unknown>;
  dataGrammarAnalysis: {
    open: boolean;
    data: string;
  };
  setDataGrammarAnalysis: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      data: string;
    }>
  >;
}

function GrammarAnalysisModal({
  grammarAnalysis,
  dataGrammarAnalysis,
  setDataGrammarAnalysis,
}: IGrammarAnalysisModalProps) {
  const theme = useTheme();
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);
  const isHasContentEdit = Boolean(editCustomAnswerContent?.data && editCustomAnswerContent?.data?.trim());
  const { data: listEnergyPrice } = useGetListEnergyPrice({});
  const dispatch = useDispatch();

  const onSaveGrammar = async () => {
    const urlUploadFile = getApiUrl(ENDPOINTS.GRAMMAR_SAVED_ADD);

    try {
      await Requests.post(urlUploadFile, {
        content: editCustomAnswerContent?.data || "",
        grammar: JSON.stringify(dataGrammarAnalysis.data),
      });
      dispatch(showMessage("Save grammar successfully!", "success"));
    } catch (e) {
      dispatch(showMessage("Save grammar unsuccessfully!", "error"));
    }
  };

  return (
    <Box>
      <Tooltip
        title={dataGrammarAnalysis.data ? "Click to see detail" : "Click to analyze grammar"}
        sx={{
          fontSize: 20,
        }}
        aria-setsize={20}
      >
        <Card
          className={clsx(["card-ai"])}
          sx={{ backgroundColor: blue["400"] }}
          onClick={() => {
            if (isHasContentEdit) {
              if (dataGrammarAnalysis.data) {
                setDataGrammarAnalysis((prev) => ({
                  ...prev,
                  open: true,
                }));
              } else {
                grammarAnalysis({
                  sentences: editCustomAnswerContent?.data || "",
                });
              }
            }
          }}
        >
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <AnalyticsIcon />
              Grammar analysis
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                ({listEnergyPrice?.types?.[LLMTypes.grammar_analysis]}{" "}
              </Typography>
              <BoltIcon
                sx={{
                  marginLeft: "2px",
                  fontSize: "18px",
                }}
                htmlColor="#fff31b"
              />
              )
            </Box>
          </Box>
        </Card>
      </Tooltip>
      <Dialog
        open={dataGrammarAnalysis.open}
        onClose={() =>
          setDataGrammarAnalysis((prev) => ({
            ...prev,
            open: false,
          }))
        }
        PaperProps={{
          sx: {
            minWidth: "60vw",
            minHeight: "30vh",
          },
        }}
      >
        <Box
          sx={() => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            overflow: "auto",
            flex: "1",
            padding: "32px",
            "& h2": {
              textAlign: "center",
              fontSize: "28px",
              fontWeight: "500",
              marginTop: "16px",
              marginBottom: "32px",
            },
            "& .btn-close": {
              position: "absolute",
              top: "20px",
              right: "20px",
              backgroundColor: grey["300"],
            },
            "& .MuiTableContainer-root": {
              marginBottom: "32px",
            },
          })}
        >
          <IconButton
            className="btn-close"
            onClick={() => {
              setDataGrammarAnalysis((prev) => ({
                ...prev,
                open: false,
              }));
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography component={"h2"}>Grammar analysis</Typography>
          {/* <IconButton
            sx={{
              position: "absolute",
              left: "10px",
              top: "10px",
            }}
            onClick={() => {
              onSaveGrammar();
            }}
          >
            <BookmarkBorderIcon />
          </IconButton> */}
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: 650,
                "& thead": {
                  backgroundColor: theme.palette.secondary.light,
                  position: "sticky",
                  top: "0",
                  "& th": {
                    fontSize: "16px",
                    color: "white",
                  },
                },
                "& td": {
                  fontSize: "16px",
                },
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Example</TableCell>
                  <TableCell>Grammar</TableCell>
                  <TableCell>Explain</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isArray(dataGrammarAnalysis.data) &&
                  dataGrammarAnalysis.data?.map((row, index) => (
                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>
                        <Box dangerouslySetInnerHTML={{ __html: row.example }} />
                      </TableCell>
                      <TableCell>{row.grammar}</TableCell>
                      <TableCell>
                        <Box dangerouslySetInnerHTML={{ __html: row.explain }} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              "& button": {
                textTransform: "none",
              },
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setDataGrammarAnalysis({
                  data: "",
                  open: false,
                });
                grammarAnalysis({
                  sentences: editCustomAnswerContent?.data || "",
                });
              }}
            >
              Try other sentences!
            </Button>
            <div style={{ width: 20 }} />
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                // setDataGrammarAnalysis({
                //   data: "",
                //   open: false,
                // });
                onSaveGrammar();
              }}
            >
              Save this grammar
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}

export { GrammarAnalysisModal };
