import { extractContent } from "@/helpers";
import CloseIcon from "@mui/icons-material/Close";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import { Box, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IApplicationState, IItems, removeIntoCart } from "../../../../../store-redux";

interface IListSuggestProps {
  handleClickIdea?: (idea: IItems) => void;
  dataOfAnswer?: IItems[];
}

export default function ListSuggest({ handleClickIdea, dataOfAnswer }: IListSuggestProps) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const location = useLocation();
  const isFromProfile = Boolean(location.state);
  const listMyIdeaPath = useSelector((state: IApplicationState) => state.myIdeaPath);
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);
  const [checkDataOfMyIdeaPath, setCheckDataOfMyIdeaPath] = useState<IItems[]>();
  const isPageAnswer = pathname.indexOf("/answer") !== -1;
  // const isGenByAI = !!listMyIdeaPath.data?.find((item) => item?.genByAI);

  useEffect(() => {
    if (isFromProfile) {
      setCheckDataOfMyIdeaPath(dataOfAnswer);
    } else {
      setCheckDataOfMyIdeaPath(listMyIdeaPath.data);
    }
  }, [listMyIdeaPath, dataOfAnswer]);

  const handleRemoveIntoCart = (idea: IItems) => {
    dispatch(removeIntoCart(idea));
  };

  const countSelected = () => {
    if (checkDataOfMyIdeaPath) {
      return checkDataOfMyIdeaPath.filter(
        (item) =>
          editCustomAnswerContent?.data?.indexOf(
            extractContent(item.description ?? "").replace(/^.*"(.*?)"[^"]*$/, "$1") ?? ""
          ) !== -1
      ).length;
    }
    return 0;
  };

  return (
    <Box
      sx={{
        paddingRight: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "16px",
          marginBottom: "8px",
        }}
      >
        <Typography>{countSelected()} selected</Typography>
        {/* <TipsAndUpdatesOutlinedIcon
          sx={{
            marginRight: "5px",
          }}
        /> */}
      </Box>
      <Box
        sx={() => ({
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          maxHeight: "calc(100vh - 230px)",
          overflow: "auto",
        })}
      >
        {checkDataOfMyIdeaPath?.map((idea: IItems) => (
          <Box
            key={idea._id}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              borderRadius: "8px",
              marginBottom: "16px",
              marginRight: "8px",
              padding: "16px",
              ...(editCustomAnswerContent?.data?.indexOf(
                extractContent(idea.description ?? "").replace(/^.*"(.*?)"[^"]*$/, "$1") ?? ""
              ) !== -1
                ? {
                    backgroundColor: "#F3F3FF",
                  }
                : {}),
              "& .box-title": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "4px",
                marginRight: "-8px",
              },
              "& .box-close": {
                display: "flex",
                alignItems: "center",
                gap: "4px",
                "& svg": {
                  fontSize: "18px",
                },
                "& .nameLocationIdea": {
                  fontWeight: "700",
                  color: "#038D00",
                  backgroundColor: "#DCFFE8",
                  padding: "0 8px",
                  borderRadius: "5px",
                  fontSize: "12px",
                },
              },
            }}
          >
            <Box
              key={idea._id}
              onClick={() => isPageAnswer && handleClickIdea?.(idea)}
              sx={() => ({
                width: "100%",
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
                position: "relative",
                minHeight: "50px",
                borderRadius: "8px",
              })}
            >
              <Box className="box-title">
                <Box
                  className="title"
                  sx={{
                    fontWeight: "bold",
                    fontSize: 14,
                  }}
                  dangerouslySetInnerHTML={{ __html: idea.name || "" }}
                />
                <Box className="box-close">
                  <Box
                    className="nameLocationIdea"
                    dangerouslySetInnerHTML={{
                      __html: idea.locationIdea == "undefined" ? " " : idea.locationIdea || "",
                    }}
                  />
                  <IconButton onClick={() => handleRemoveIntoCart(idea)}>
                    <CloseIcon htmlColor="gray" />
                  </IconButton>
                </Box>
              </Box>

              <Box
                className="description"
                sx={{
                  fontSize: 14,
                }}
                dangerouslySetInnerHTML={{
                  __html: idea.description || "",
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
