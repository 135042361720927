import { convertToLocalTime, extractContent } from "@/helpers";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { IMyPracticesOutput } from "../../../../store-redux";
import { forEach, groupBy } from "lodash";
import { EQuestionNumber } from "@/constants";

interface IListInfoAnwerProps {
  idTopics: string;
  handleClick: (item: IMyPracticesOutput) => void;
  dataMyPractices: IMyPracticesOutput[];
  selectPart: EQuestionNumber;
}

interface IListPracticeItem {
  id: string;
  content: string;
  listItems: IMyPracticesOutput[];
}

export default function ListInfoAnwer({ idTopics, handleClick, dataMyPractices, selectPart }: IListInfoAnwerProps) {
  const [filterPracticeByTopicId, setFilterPracticeByTopicId] = useState<IListPracticeItem[]>();

  useEffect(() => {
    if (idTopics) {
      const answerIdTopic = dataMyPractices?.filter((item) => {
        const questionContent = extractContent(item.questionId?.name ?? "");
        return item?.questionId.parentId === idTopics && questionContent?.split(" ")?.[0] === selectPart;
      });
      const groupByQuestionId = groupBy(
        answerIdTopic?.sort((a, b) => a.questionId.order - b.questionId.order),
        "questionId._id"
      );
      const listItemWithAnswer = Object.keys(groupByQuestionId).map((key) => ({
        id: key,
        content: groupByQuestionId[key][0].questionId.name,
        listItems: groupByQuestionId[key],
      }));
      setFilterPracticeByTopicId(listItemWithAnswer);
    }
  }, [dataMyPractices, idTopics, selectPart]);
  const calculateBand = (value: IMyPracticesOutput) => {
    const result = value.result;
    let total = 0;
    forEach(result, (item) => {
      total += item.band;
    });
    return Math.round((total / (result?.length || 4)) * 2) / 2;
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
        maxWidth: "calc(100vw - 32px)",
        maxHeight: "calc(100vh - 352px)",
        paddingRight: "10px",
        "& li": {
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          gap: "10px",
          border: "1px solid #EDEDED",
          borderRadius: "8px",
          marginBottom: "10px",
          padding: "8px",
        },
        "& .text": {
          letterSpacing: "0em",
          textAlign: "left",
        },
        "& h4": {
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "24px",
          fontFamily: "Poppins",
        },
        "& .content-title": {
          display: "flex",
          "& h4": {
            fontWeight: "bold",
            marginRight: "8px",
          },
        },
        "& .content-answer": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #e1e1e1",
          paddingBottom: "8px",
          "& .info-answer": {
            maxWidth: "calc(100vw - 82px)",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flexDirection: "column",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ECEAEA",
            },
            "& .info-time": {
              display: "flex",
              gap: "20px",
            },
            "& h4": {
              fontStyle: "italic",
              color: "#827e7e",
            },
          },
          "&:last-child": {
            borderBottom: "none",
          },
        },
      }}
    >
      {filterPracticeByTopicId?.map((practice) => (
        <Box component={"li"} key={practice.id}>
          <Box className="content-title">
            <Typography
              component={"h4"}
              className="text"
              dangerouslySetInnerHTML={{
                __html: practice.content || "",
              }}
            />
            ({practice.listItems?.length})
          </Box>
          {practice.listItems
            ?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            ?.map((item) => (
              <Box className="content-answer" key={item._id}>
                <Box onClick={() => handleClick(item)} className="info-answer">
                  <Box>
                    <Typography
                      component={"h4"}
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: item.voiceTranscript || "",
                      }}
                      sx={{
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 2 /* number of lines to show */,
                        WebkitBoxOrient: "vertical",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Typography
                      component={"h4"}
                      className="text"
                      style={{
                        fontStyle: "italic",
                        color: "#70c848",
                      }}
                    >
                      {convertToLocalTime(Number(item.createdAt))}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      {item?.result ? (
                        <Typography
                          component={"span"}
                          sx={{
                            marginLeft: "10px",
                            color: "#fe3939",
                          }}
                        >
                          (score: <b>{calculateBand(item)}</b>)
                        </Typography>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
      ))}
    </Box>
  );
}
