import { ENDPOINTS, getApiUrl, IItems, Requests } from "@/common";
import { extractContent, getUrlServer, randomStringFromDatetime, refinePrompt } from "@/helpers";
import { IApplicationState } from "@/store-redux";
import { Avatar, Box, Button, IconButton, Input, LinearProgress, useTheme } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import BotIcon from "./../../../../assets/bot.png";
import SendIcon from "@mui/icons-material/Send";
import ListIdeaAI from "./ListIdeaAI";

interface FreestyleToIdeaProps {
  question: IItems | null;
}

enum EChatStatus {
  init = "init",
  start = "start",
  end = "end",
}

const FreestyleToIdea = ({ question }: FreestyleToIdeaProps) => {
  const theme = useTheme();
  const [chatStatus, setChatStatus] = useState(EChatStatus.init);
  const currentUser = useSelector((state: IApplicationState) => state.currentUser);
  const [converstation, setConverstation] = useState<string[]>([]);
  const [inputAns, setInputAns] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [idea, setIdea] = useState(null);
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const onSubmitAPI = async () => {
    setIsLoading(true);
    const apiUrl = getApiUrl(ENDPOINTS.FREESTYLE_TO_IDEA);
    const conversationTemp = [...converstation];
    if (chatStatus === EChatStatus.end) {
      conversationTemp.pop();
    }
    const res = await Requests.post(apiUrl, {
      question: refinePrompt(extractContent(question?.name ?? "")),
      conversation: conversationTemp,
      isStop: chatStatus === EChatStatus.end,
    });
    if (res.data.result) {
      if (chatStatus === EChatStatus.end) {
        if (Array.isArray(res.data?.result) && res.data?.result.length > 0) {
          const item = res.data?.result[0];
          setIdea({
            genByAI: true,
            _id: randomStringFromDatetime(),
            name: `${item.title} (${item.detail})`,
            description: item.example_answer,
            locationIdea: "",
          });
        }
      } else {
        setConverstation((prev) => [...prev, res.data.result]);
      }
    }
    setIsLoading(false);
  };

  const onSubmitAns = () => {
    if (converstation.length % 2 === 1) {
      setConverstation((prev) => [...prev, inputAns]);
    }
    setInputAns("");
  };

  useEffect(() => {
    if ((converstation.length % 2 === 0 && chatStatus === EChatStatus.start) || chatStatus === EChatStatus.end) {
      onSubmitAPI();
    }
  }, [converstation, chatStatus]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [converstation]);
  const showMessageSystem = (content: string) => {
    return (
      <Box
        sx={{
          display: "flex",
          marginBottom: "20px",
          "& img": {
            width: "32px",
            height: "32px",
            marginRight: "5px",
          },
        }}
      >
        <img src={BotIcon} />
        <Box
          sx={{
            backgroundColor: "#F3F3FF",
            color: "#313131",
            fontSize: "14px",
            padding: "5px",
            borderRadius: "0 10px 10px 10px",
            maxWidth: "70%",
            "& span": {
              fontWeight: "bold",
            },
          }}
        >
          {content}
        </Box>
      </Box>
    );
  };

  const showMessageUser = (content: string) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#f1f1f1",
            padding: "10px",
            borderRadius: "10px 0 10px 10px",
            maxWidth: "70%",
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              "& *": {
                fontSize: "14px",
              },
            }}
            dangerouslySetInnerHTML={{ __html: content || "" }}
          ></Box>
        </Box>
        <Avatar
          sx={{
            marginLeft: "10px",
            fontSize: "32px",
            color: "#ff7269",
          }}
          src={currentUser.data?.avatarPath ? `${getUrlServer(currentUser.data?.avatarPath)}` : "/images/people.png"}
        />
      </Box>
    );
  };

  const inputDisabled = inputAns.trim() === "" || isLoading;
  return (
    <Box
      sx={{
        marginBottom: "10px",
      }}
    >
      {isLoading && (
        <Box
          sx={{
            height: "10px",
          }}
        >
          <LinearProgress color="primary" />
        </Box>
      )}
      {chatStatus === EChatStatus.init && !isLoading && (
        <Button
          onClick={() => {
            setChatStatus(EChatStatus.start);
            // onSubmitAns();
          }}
          variant="contained"
          color="primary"
          sx={{
            textTransform: "unset",
          }}
        >
          Bắt đầu
        </Button>
      )}
      {(chatStatus === EChatStatus.start || chatStatus === EChatStatus.end) && (
        <Box
          sx={{
            height: "300px",
            overflowY: "auto",
          }}
        >
          {converstation
            .slice(0, chatStatus === EChatStatus.end ? -1 : undefined)
            .map((item, index) => (index % 2 === 0 ? showMessageSystem(item) : showMessageUser(item)))}
          <div ref={bottomRef} />
        </Box>
      )}
      {chatStatus === EChatStatus.start && (
        <>
          {!isLoading && converstation.length >= 2 && (
            <Box>
              <Button
                onClick={() => {
                  setChatStatus(EChatStatus.end);
                }}
                variant="contained"
                color="primary"
                sx={{
                  textTransform: "unset",
                }}
              >
                Stop & Gen idea
              </Button>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              padding: "12px",
            }}
          >
            <Input
              sx={{
                flex: 1,
                border: "1px solid #e0e0e0",
                borderRadius: "10px",
                padding: "8px",
                "&::after": {
                  display: "none",
                },
                "&::before": {
                  display: "none",
                },
              }}
              value={inputAns}
              onChange={(event) => setInputAns(event.target.value.replace("\n", ""))}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !inputDisabled) {
                  onSubmitAns();
                }
              }}
              multiline
              rows={4}
              disabled={isLoading}
              placeholder="Enter my answer ..."
            />
            <IconButton disabled={inputDisabled} onClick={() => onSubmitAns()}>
              <SendIcon
                sx={
                  inputDisabled
                    ? {}
                    : {
                        color: theme.palette.primary.main,
                      }
                }
              />
            </IconButton>
          </Box>
        </>
      )}
      {chatStatus === EChatStatus.end && idea && (
        <>
          <ListIdeaAI ideas={[idea]} />
        </>
      )}
    </Box>
  );
};

export default memo(FreestyleToIdea);
