import { LLMTypes } from "@/constants";
import { useGetListEnergyPrice } from "@/services-react-query/queries";
import { ScoreBand } from "@/store-redux";
import SendIcon from "@mui/icons-material/Send";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  Box,
  Card,
  IconButton,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import BoltIcon from "@mui/icons-material/Bolt";

interface IRepharseAnswerPopoverProps {
  onRephraseAnswer: (level: string) => void;
}

function RepharseAnswerPopover({ onRephraseAnswer }: IRepharseAnswerPopoverProps) {
  const [level, setLevel] = useState(ScoreBand["5_0"]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: listEnergyPrice } = useGetListEnergyPrice({});

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setLevel(event.target.value as ScoreBand);
  };

  const onClickSubmit = () => {
    handleClose();
    if (level) {
      onRephraseAnswer(level);
    }
  };

  return (
    <div>
      <Tooltip
        title={"Click to rephrase sentence "}
        sx={{
          fontSize: 20,
        }}
        aria-setsize={20}
      >
        <Card
          className={clsx(["card-ai"])}
          onClick={handleClick}
          sx={{
            backgroundColor: "#fe5dc5",
          }}
        >
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <SwapVertIcon />
              Rephrase sentence
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "bold" }}>({listEnergyPrice?.types?.[LLMTypes.repharse]} </Typography>
              <BoltIcon
                sx={{
                  marginLeft: "2px",
                  fontSize: "18px",
                }}
                htmlColor="#fff31b"
              />
              )
            </Box>
          </Box>
        </Card>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Typography sx={{}}>Please choose IELTS level</Typography>
          <Box sx={{ display: "flex", gap: "10px", marginTop: "10px", justifyContent: "space-between" }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={level}
              onChange={handleChange}
              sx={{
                height: "48px",
              }}
            >
              {Object.values(ScoreBand).map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            <IconButton
              sx={{
                height: "48px",
                width: "48px",
              }}
              onClick={onClickSubmit}
            >
              <SendIcon color="secondary" />
            </IconButton>
          </Box>
        </Box>
      </Popover>
    </div>
  );
}

export { RepharseAnswerPopover };
