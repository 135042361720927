import React, { useState } from "react";
import MatchingExercise from "./components/MatchingExercise";
import FillBlanksExercise from "./components/FillBlanksExercise";
import MultipleChoiceExercise from "./components/MultipleChoiceExercise";
import VerbFormsExercise from "./components/VerbFormsExercise";
import SentenceFormationExercise from "./components/SentenceFormationExercise";
import { Box, Dialog, IconButton } from "@mui/material";
import "./styles.scss";
import CloseIcon from "@mui/icons-material/Close";
import { ExerciseData, ExerciseResults, IUserAnswers } from "./types/exercise.types";
import ResultsSummary from "./components/ResultsSummary";
import FillBlanksGrammar from "./components/FillBlanksGrammar";

// const exerciseData = {
//   vocabulary_exercises: {
//     matching: {
//       instructions: "Match the Vietnamese words with their English equivalents",
//       pairs: [
//         {
//           vietnamese: "mặt đất",
//           english: "ground level",
//           correct_pair: "A-2",
//         },
//         {
//           vietnamese: "tầng",
//           english: "floor/story",
//           correct_pair: "B-4",
//         },
//         {
//           vietnamese: "phòng khách",
//           english: "living room",
//           correct_pair: "C-3",
//         },
//         {
//           vietnamese: "phòng ngủ",
//           english: "bedroom",
//           correct_pair: "D-5",
//         },
//         {
//           vietnamese: "phòng thờ",
//           english: "altar room",
//           correct_pair: "E-6",
//         },
//         {
//           vietnamese: "sân phơi",
//           english: "drying area",
//           correct_pair: "F-1",
//         },
//       ],
//     },
//     fill_in_blanks: {
//       instructions: "Complete the sentences using the words provided",
//       word_bank: ["story", "ground", "living room", "bedroom", "workspace", "altar", "drying area"],
//       sentences: [
//         {
//           sentence: "I live in a three-_____ house at _____ level.",
//           answers: ["story", "ground"],
//         },
//         {
//           sentence: "On the first floor, there's a _____ and a _____.",
//           answers: ["living room", "bedroom"],
//         },
//         {
//           sentence: "The third floor has an _____ room and a _____.",
//           answers: ["altar", "drying area"],
//         },
//       ],
//     },
//     multiple_choice: {
//       questions: [
//         {
//           question: "A house divided into different levels is called a _____ house.",
//           options: ["multi-story", "apartment", "flat", "duplex"],
//           correct_answer: "multi-story",
//         },
//         {
//           question: "A private space where you can work is called a _____.",
//           options: ["study room", "workspace", "office", "all of the above"],
//           correct_answer: "all of the above",
//         },
//       ],
//     },
//   },
//   grammar_exercises: {
//     verb_forms: {
//       instructions: "Put the verbs in correct form (Present Simple)",
//       sentences: [
//         {
//           sentence: "I (live) in a three-story house.",
//           answer: "live",
//         },
//         {
//           sentence: "The house (have) six rooms.",
//           answer: "has",
//         },
//         {
//           sentence: "Each floor (include) different rooms.",
//           answer: "includes",
//         },
//       ],
//     },
//     sentence_formation: {
//       instructions: "Arrange the words to make complete sentences",
//       sentences: [
//         {
//           words: ["live", "in", "I", "a", "three-story", "house"],
//           answer: "I live in a three-story house",
//         },
//         {
//           words: ["has", "the", "first", "floor", "two", "rooms"],
//           answer: "The first floor has two rooms",
//         },
//         {
//           words: ["because", "I", "like", "it", "I", "have", "privacy"],
//           answer: "I like it because I have privacy",
//         },
//       ],
//     },
//     fill_in_blanks: {
//       instructions: "Fill in the correct prepositions",
//       sentences: [
//         {
//           sentence: "I live ___ a house ___ ground level.",
//           answers: ["in", "at"],
//         },
//         {
//           sentence: "The altar room is ___ the third floor.",
//           answer: "on",
//         },
//         {
//           sentence: "I enjoy living ___ this house.",
//           answer: "in",
//         },
//       ],
//     },
//   },
//   students_task: {
//     example_answer:
//       "I live in an apartment building that has 5 rooms. It has full amenities like air conditioning and a washing machine. My apartment is located in the city center, surrounded by a supermarket and a shopping mall.",
//     instruction:
//       "Using the vocabulary and grammar learned, create a full sentence paragraph in English describing your housing.",
//   },
// };

interface ILearnToIdeaProps {
  exerciseData?: ExerciseData;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function LearnToIdea({ exerciseData, openModal, setOpenModal }: ILearnToIdeaProps) {
  // const [showModelAnswer, setShowModelAnswer] = useState<boolean>(false);

  const [results, setResults] = useState<ExerciseResults>({
    totalScore: 0,
    totalQuestions: 0,
    multipleChoice: { score: 0, total: 0, answers: {} },
    verbForms: { score: 0, total: 0, answers: {} },
    sentenceFormation: { score: 0, total: 0, answers: {} },
    matching: { score: 0, total: 0, answers: {} },
    fillBlanks: { score: 0, total: 0, answers: {} },
    grammarBlanks: { score: 0, total: 0, answers: {} },
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userAnswers, setUserAnswers] = useState<IUserAnswers>({
    matching: {},
    fillBlanks: {},
    multipleChoice: {},
    verbForms: {},
    sentenceFormation: {},
    grammarBlanks: {},
  });

  if (!exerciseData) return <></>;

  const checkMatchingAnswers = () => {
    const score = exerciseData.vocabulary_exercises.matching.pairs.reduce((acc, pair) => {
      const userAnswer = (userAnswers.matching as Record<string, string>)?.[pair.vietnamese] || "";
      return userAnswer === pair.english ? acc + 1 : acc;
    }, 0);

    return {
      score,
      total: exerciseData.vocabulary_exercises.matching.pairs.length,
      answers: userAnswers.matching || {},
    };
  };

  const checkFillBlanksAnswers = () => {
    let score = 0;
    let total = 0;

    exerciseData.vocabulary_exercises.fill_in_blanks.sentences.forEach((sentence, sentenceIndex) => {
      sentence.answers.forEach((answer, blankIndex) => {
        total++;
        const userAnswer =
          userAnswers.fillBlanks?.[`${sentenceIndex}-${blankIndex}` as keyof typeof userAnswers.fillBlanks];
        if (typeof userAnswer === "string" && userAnswer.toLowerCase() === answer.toLowerCase()) {
          score++;
        }
      });
    });

    return {
      score,
      total,
      answers: userAnswers.fillBlanks || {},
    };
  };

  const checkMultipleChoiceAnswers = () => {
    const score = exerciseData.vocabulary_exercises.multiple_choice.questions.reduce((acc, question, index) => {
      const userAnswer = (userAnswers.multipleChoice as Record<number, string>)?.[index];
      return userAnswer === question.correct_answer ? acc + 1 : acc;
    }, 0);

    return {
      score,
      total: exerciseData.vocabulary_exercises.multiple_choice.questions.length,
      answers: userAnswers.multipleChoice || {},
    };
  };

  const checkVerbFormsAnswers = () => {
    const score = exerciseData.grammar_exercises.verb_forms.sentences.reduce((acc, sentence, index) => {
      const userAnswer = (userAnswers.verbForms as Record<number, string>)?.[index];
      return userAnswer?.toLowerCase() === sentence.answer.toLowerCase() ? acc + 1 : acc;
    }, 0);

    return {
      score,
      total: exerciseData.grammar_exercises.verb_forms.sentences.length,
      answers: userAnswers.verbForms || {},
    };
  };

  const checkSentenceFormationAnswers = () => {
    const score = exerciseData.grammar_exercises.sentence_formation.sentences.reduce((acc, sentence, index) => {
      const userAnswer = (userAnswers.sentenceFormation as Record<number, string[]>)?.[index]?.join(" ");
      return userAnswer === sentence.answer ? acc + 1 : acc;
    }, 0);

    return {
      score,
      total: exerciseData.grammar_exercises.sentence_formation.sentences.length,
      answers: userAnswers.sentenceFormation || {},
    };
  };

  const checkGrammarBlanksAnswers = () => {
    let score = 0;
    let total = 0;
    exerciseData.grammar_exercises.fill_in_blanks.sentences.forEach((sentence, sentenceIndex) => {
      const answers = Array.isArray(sentence.answers) ? sentence.answers : sentence.answers;
      answers.forEach((answer, blankIndex) => {
        total++;
        const userAnswer = (userAnswers.grammarBlanks as Record<string, string>)?.[`${sentenceIndex}-${blankIndex}`];
        if (userAnswer?.toLowerCase() === answer.toLowerCase()) {
          score++;
        }
      });
    });

    return {
      score,
      total,
      answers: userAnswers.grammarBlanks || {},
    };
  };

  const calculateResults = () => {
    const newResults = {
      matching: checkMatchingAnswers(),
      fillBlanks: checkFillBlanksAnswers(),
      multipleChoice: checkMultipleChoiceAnswers(),
      verbForms: checkVerbFormsAnswers(),
      sentenceFormation: checkSentenceFormationAnswers(),
      grammarBlanks: checkGrammarBlanksAnswers(),
    };

    const totalScore = Object.values(newResults).reduce((acc, curr) => acc + curr.score, 0);
    const totalQuestions = Object.values(newResults).reduce((acc, curr) => acc + curr.total, 0);

    setResults({
      ...newResults,
      totalScore,
      totalQuestions,
    });
    setIsSubmitted(true);
  };

  return (
    <Dialog open={openModal} onClose={() => setOpenModal(false)} fullScreen>
      <Box
        sx={{
          padding: "32px",
        }}
        className="free-learn-to-idea__container"
      >
        <Box
          sx={{
            position: "fixed",
            right: "30px",
            top: "20px",
          }}
        >
          <IconButton
            size="small"
            sx={{
              backgroundColor: "gray",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "gray",
              },
            }}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <CloseIcon
              fontSize="small"
              sx={{
                color: "#fff",
              }}
            />
          </IconButton>
        </Box>
        <div className="app-container">
          <h1>Vocabulary and Grammar Exercises</h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              calculateResults();
            }}
          >
            <div className="exercises-wrapper">
              <MatchingExercise
                data={exerciseData.vocabulary_exercises.matching}
                isSubmitted={isSubmitted}
                setUserAnswers={setUserAnswers}
              />
              <FillBlanksExercise
                data={exerciseData.vocabulary_exercises.fill_in_blanks}
                isSubmitted={isSubmitted}
                setUserAnswers={setUserAnswers}
              />
              <MultipleChoiceExercise
                data={exerciseData.vocabulary_exercises.multiple_choice}
                isSubmitted={isSubmitted}
                setUserAnswers={setUserAnswers}
              />
              <VerbFormsExercise
                data={exerciseData.grammar_exercises.verb_forms}
                isSubmitted={isSubmitted}
                setUserAnswers={setUserAnswers}
              />
              <SentenceFormationExercise
                data={exerciseData.grammar_exercises.sentence_formation}
                isSubmitted={isSubmitted}
                setUserAnswers={setUserAnswers}
              />
              <FillBlanksGrammar
                data={exerciseData.grammar_exercises.fill_in_blanks}
                isSubmitted={isSubmitted}
                setUserAnswers={setUserAnswers}
              />
              {/* <PrepositionsExercise data={exerciseData.grammar_exercises.prepositions} /> */}
              {/* <ModelAnswer data={exerciseData.model_answer} /> */}

              {!isSubmitted ? (
                <button type="submit" className="submit-all-btn">
                  Submit All Answers
                </button>
              ) : (
                <ResultsSummary
                  results={results}
                  onTryAgain={() => {
                    setIsSubmitted(false);
                    setUserAnswers({
                      matching: {},
                      fillBlanks: {},
                      multipleChoice: {},
                      verbForms: {},
                      sentenceFormation: {},
                      grammarBlanks: {},
                    });
                  }}
                />
              )}
            </div>
          </form>
        </div>
      </Box>
    </Dialog>
  );
}

export default LearnToIdea;
