import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import AbcIcon from "@mui/icons-material/Abc";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import _ from "lodash";
import { INIT_GRAMMAR_TOPICS } from "@/constants";
import { EBackOfficeCode } from "@/common/types/backoffice";
import GrammarExample from "@/pages/CMS/GrammarCMSPage/GrammarExample";

interface GrammarSuggestBtnProps {}

interface GrammarData {
  grammarTopic: string;
  examples: string[][];
}

interface Params {
  questionId: string;
}

const GrammarSuggestBtn: React.FC<GrammarSuggestBtnProps> = (props) => {
  const { questionId } = useParams<Params>();
  const [openSuggest, setOpenSuggest] = useState<boolean>(false);
  const [grammarTopics, setGrammarTopics] = useState<string[]>([]);
  const [examplesByTopic, setExamplesByTopic] = useState<Record<string, string[][]>>({});

  const getBackofficeGrammarTopic = async () => {
    try {
      const dataRes = await Requests.get(
        getApiUrl(ENDPOINTS.BACKOFFICE_GET_BY_CODE.replace(":code", EBackOfficeCode.GRAMMAR_TOPIC_CMS))
      );
      if (Array.isArray(dataRes?.data)) {
        const data = dataRes?.data as string[];
        setGrammarTopics(data);
      } else {
        setGrammarTopics(INIT_GRAMMAR_TOPICS);
      }
    } catch (e) {
      setGrammarTopics(INIT_GRAMMAR_TOPICS);
    }
  };

  useEffect(() => {
    if (openSuggest && _.isEmpty(examplesByTopic)) {
      getGrammarByQuestionId();
    }
    if (openSuggest && _.isEmpty(grammarTopics)) {
      getBackofficeGrammarTopic();
    }
  }, [openSuggest]);

  const getGrammarByQuestionId = async () => {
    const url = getApiUrl(ENDPOINTS.GET_GRAMMAR_BY_QUESTION.replace(":questionId", questionId));
    const data = await Requests.get(url);
    const dataJson: Record<string, string[][]> = {};
    (data as GrammarData[]).forEach((item) => {
      dataJson[item.grammarTopic] = item.examples;
    });
    setExamplesByTopic(dataJson);
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        sx={{
          textTransform: "initial",
          backgroundColor: "#46C2CB",
        }}
        startIcon={<AbcIcon />}
        onClick={() => {
          setOpenSuggest(true);
        }}
      >
        Grammar
      </Button>
      <Modal
        open={openSuggest}
        onClose={() => {
          setOpenSuggest(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            padding: "10px",
            borderRadius: "8px",
            maxHeight: "calc(100vh - 40px)",
            maxWidth: "calc(100vw - 40px)",
            width: "1000px",
            height: "800px",
          }}
        >
          <Box
            sx={{
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <AbcIcon />
              <Typography
                sx={{
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              >
                Grammar Suggestion
              </Typography>
            </Box>
            <IconButton
              onClick={() => {
                setOpenSuggest(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              overflowY: "auto",
              height: "calc(100% - 50px)",
            }}
          >
            {grammarTopics.map((grammarTopic) => (
              <Box
                key={grammarTopic}
                sx={{
                  borderBottom: "2px solid #eceaea",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    padding: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "10px",
                      cursor: "pointer",
                      flex: 1,
                    }}
                  >
                    {grammarTopic}
                  </Typography>
                </Box>
                {Array.isArray(examplesByTopic[grammarTopic]) && (
                  <Box
                    sx={{
                      padding: "10px",
                    }}
                  >
                    {examplesByTopic[grammarTopic].map((example, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          borderBottom: "0.5px solid #ccc",
                          padding: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            marginRight: "5px",
                            backgroundColor: "#264653",
                            color: "#fff",
                            width: "24px",
                            height: "24px",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {index + 1}
                        </Box>
                        <GrammarExample example={example} disabled={true} />
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default GrammarSuggestBtn;
