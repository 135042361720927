import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GenVocaByBand from "./components/GenVocaByBand";
import { ENDPOINTS, getApiUrl, Requests, ScoreBand } from "@/common";
import { ROUTES } from "@/constants";


const listScoreBand = [
  {
    code: ScoreBand["5_0"],
    name: "5.0",
  },
  {
    code: ScoreBand["5_5"],
    name: "5.5",
  },
  {
    code: ScoreBand["6_0"],
    name: "6.0",
  },
  {
    code: ScoreBand["6_5"],
    name: "6.5",
  },
  {
    code: ScoreBand["7_0"],
    name: "7.0",
  },
  {
    code: ScoreBand["7_5"],
    name: "7.5",
  },
  {
    code: ScoreBand["8_0"],
    name: "8.0",
  },
  {
    code: ScoreBand["8_5"],
    name: "8.5",
  },
  {
    code: ScoreBand["9_0"],
    name: "9.0",
  },
];
export interface Question {
  _id: string;
  name: string;
}
const LearnVocaByQuestion = () => {
  const navigate = useNavigate();
  const { questionId } = useParams();
  const [question, setQuestion] = useState<Question | null>(null);
  const [bandActive, setBandActive] = useState<ScoreBand | null>(null);
  const getQuestionInfo = async () => {
    const dataRes = await Requests.get(getApiUrl(ENDPOINTS.ONE_ITEM.replace(":id", questionId ?? "")));
    if (dataRes.data) setQuestion(dataRes.data);
  };
  useEffect(() => {
    getQuestionInfo();
  }, []);
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => {
            navigate(ROUTES.CMS_LEARN_VOCA);
          }}
          color="primary"
          sx={{
            textTransform: "none",
            fontWeight: "bold",
          }}
        >
          Back
        </Button>
        <Box dangerouslySetInnerHTML={{ __html: question?.name ?? "" }}></Box>
      </Box>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            minWidth: "120px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Score band
          </Typography>
          <Box
            sx={{
              maxHeight: "calc(100vh - 150px)",
              overflowY: "auto",
            }}
          >
            {listScoreBand.map((band, idx) => {
              return (
                <Box
                  sx={{
                    padding: "8px",
                    cursor: "pointer",
                    ...(bandActive === band.code
                      ? {
                          fontWeight: "bold",
                        }
                      : {}),
                  }}
                  onClick={() => setBandActive(band.code)}
                  key={idx}
                >
                  <Typography
                    color={bandActive === band.code ? "primary" : "#000"}
                    sx={{
                      ...(bandActive === band.code
                        ? {
                            fontWeight: "bold",
                          }
                        : {}),
                    }}
                  >
                    {band.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          {bandActive && <GenVocaByBand band={bandActive} question={question} />}
        </Box>
      </Box>
    </Box>
  );
};
export default LearnVocaByQuestion;
