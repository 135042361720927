import React, { useEffect, useState } from "react";
import { Box, Typography, List, ListItem, ListItemText, TextField, Button, Snackbar, Grid } from "@mui/material";
import { Alert } from "@mui/lab"; // or '@mui/material/Alert' based on your MUI version
import axios from "axios";
import { ENDPOINTS, getApiUrl, Requests } from "@/common";

const MyClassPage = ({}) => {
  const [joinedClasses, setJoinedClasses] = useState([]);
  const [inviteCode, setInviteCode] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // Fetch all classes the student has joined
  const fetchJoinedClasses = async () => {
    try {
      //   const response = await axios.get(`/class/student/${studentId}`);
      const dataRes = await Requests.get(getApiUrl(ENDPOINTS.STUDENT_GET_LIST_MY_CLASS));
      console.log("dataRes", dataRes);
      if (Array.isArray(dataRes)) setJoinedClasses(dataRes);
    } catch (error) {
      console.error("Error fetching classes:", error);
      setSnackbarMessage("Failed to load classes.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    fetchJoinedClasses();
  }, []);

  // Handle invite code submission
  const handleJoinClass = async () => {
    try {
      const apiUrl = getApiUrl(ENDPOINTS.JOIN_CLASS);
      await Requests.post(apiUrl, {
        inviteCode: inviteCode,
      });
      setSnackbarMessage("Joined class successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      fetchJoinedClasses();
      setInviteCode("");
    } catch (error) {
      console.error("Error joining class:", error);
      setSnackbarMessage("Failed to join class. Please check the invite code.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  console.log("joinedClasses", joinedClasses);
  return (
    <Box p={2}>
      <Box>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: "bold",
          }}
        >
          My Classes
        </Typography>
      </Box>
      <Typography>Enter Invite Code to Join a Class</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          paddingTop: "8px",
        }}
      >
        <TextField
          label="Invite Code"
          variant="outlined"
          value={inviteCode}
          onChange={(e) => setInviteCode(e.target.value)}
          fullWidth
          sx={{
            width: "180px",
            marginRight: "8px",
            "& input": {
              padding: "8px",
            },
            "& .MuiFormLabel-root": {
              fontSize: "14px",
              marginTop: "-4px",
            },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleJoinClass}
          sx={{
            textTransform: "none",
          }}
        >
          Join Class
        </Button>
      </Box>
      <Grid
        container
        sx={{
          height: "calc(100vh - 80px)",
          overflowY: "auto",
        }}
      >
        {joinedClasses.map((classItem) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={3}
            sx={{
              padding: "10px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F3F3FF",
                padding: "8px",
                borderRadius: "8px",
                cursor: "pointer",
                "& img": {
                  height: "48px",
                  width: "48px",
                  marginRight: "16px",
                },
              }}
            >
              <Typography
                sx={{
                  "& span": { fontWeight: "bold" },
                }}
                color="primary"
              >
                Class: <span>{classItem?.title ?? ""}</span>
              </Typography>
              <Typography
                sx={{
                  "& span": { fontWeight: "bold" },
                }}
                color="primary"
              >
                Teacher: <span>{classItem?.teacher?.username ?? ""}</span>
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  margin: "8px 0",
                  "& span": { fontWeight: "bold" },
                }}
              >
                Invite code: <span>{classItem?.invite_code ?? ""}</span>
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MyClassPage;
