import { IPracticeEvaluateOutput } from "@/common";
import { getUrlServer } from "@/helpers";
import { Box, Card, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import Markdown from "react-markdown";

interface IDetailPracticeProps {
  voiceTranscript: IPracticeEvaluateOutput["voiceTranscript"];
  gptEvaluates: IPracticeEvaluateOutput["gptEvaluates"];
  isShowVoice?: boolean;
  voicePath?: string;
}

function DetailPractice({ voiceTranscript, gptEvaluates, isShowVoice = false, voicePath }: IDetailPracticeProps) {
  const theme = useTheme();
  const replaceText = (text: string) => {
    return text.replace(/<\/band>/g, "").replace(/<band>/g, "Band ");
  };
  const [activeEvaluate, setActiveEvaluate] = useState(0);

  const renderData = () => {
    const findValue = gptEvaluates[activeEvaluate];
    if (findValue) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Typography component={"span"} sx={{ fontWeight: "bold", fontSize: 18 }}>
            {findValue.criterium}
          </Typography>
          {findValue.explanation ? (
            <>
              <Typography component={"span"} sx={{ fontWeight: "bold", fontSize: 16 }}>
                Explanation
              </Typography>
              <Typography component={"span"} sx={{ fontSize: 16 }}>
                <Markdown>{replaceText(findValue.explanation)}</Markdown>
              </Typography>
            </>
          ) : null}
          {findValue.suggestion ? (
            <>
              <Typography component={"span"} sx={{ fontWeight: "bold", fontSize: 16 }}>
                Suggestion
              </Typography>
              <Typography component={"span"} sx={{ fontSize: 16 }}>
                {findValue.suggestion}
              </Typography>
            </>
          ) : null}
        </Box>
      );
    }
    return;
  };

  return (
    <Box minHeight={"30vh"}>
      {/* {isShowVoice && voicePath ? (
        <Box>
          <Typography component={"span"} sx={{ fontWeight: "bold", fontSize: 18 }}>
            Your answer
          </Typography>
          <Box
            sx={{
              padding: "16px",
              marginTop: "16px",
              backgroundColor: "#F7F7F7",
              borderRadius: "8px",
            }}
          >
            <audio src={`${getUrlServer(voicePath)}`} controls></audio>
          </Box>
        </Box>
      ) : null} */}
      <Box>
        <Typography component={"span"} sx={{ fontWeight: "bold", fontSize: 18 }}>
          Your transcript
        </Typography>
        <Card
          sx={{
            padding: "16px",
            marginTop: "16px",
            backgroundColor: "#F7F7F7",
            borderRadius: "8px",
          }}
        >
          {voiceTranscript}
        </Card>
      </Box>
      {gptEvaluates ? (
        <Box mt={2} sx={{ display: "flex", flexDirection: "column" }}>
          <Typography component={"span"} sx={{ marginBottom: "16px", fontWeight: "bold", fontSize: 18 }}>
            Your result
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              marginBottom: "16px",
              "&>div": {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                flex: 1,
                paddingBlock: "16px",
                border: "1px solid #C6C1C1",
                cursor: "pointer",
                "&.active": {
                  borderColor: theme.palette.primary.main,
                },
                "& .band": {
                  fontWeight: "600",
                  fontSize: "24px",
                },
                "& .criterium": {
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#6A6969",
                },
              },
            }}
          >
            {gptEvaluates?.map((item, key) => (
              <Box key={key} onClick={() => setActiveEvaluate(key)} className={activeEvaluate === key ? "active" : ""}>
                <Typography component={"span"} className="band">
                  {item.band}
                </Typography>
                <Typography component={"span"} className="criterium">
                  {item.criterium}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              padding: "16px",
              backgroundColor: "#F7F7F7",
              borderRadius: "8px",
            }}
          >
            {renderData()}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}

export default DetailPractice;
