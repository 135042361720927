import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
interface ListStudentClassProps {
  students: any[];
}
const ListStudentClass = ({ students }: ListStudentClassProps) => {
  return (
    <Box>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  fontWeight: "bold",
                  padding: "8px",
                },
              }}
            >
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Join at</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((item, index) => (
              <TableRow
                key={index}
                sx={{
                  td: {
                    padding: "8px",
                  },
                }}
              >
                <TableCell>{item.student?.username ?? ""}</TableCell>
                <TableCell>{item.student?.email ?? ""}</TableCell>
                <TableCell>{moment(item.join_at).format("lll")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ListStudentClass;
