import { useGetListEnergyPrice, useGetQuota } from "@/services-react-query/queries";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { IApplicationState, SuggestVocabularyBand } from "../../../../../../store-redux";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import { LLMTypes } from "@/constants";
import BoltIcon from "@mui/icons-material/Bolt";

interface ISuggestVocabularyPopoverProps {
  repharseLoading: boolean;
  onSuggestVoca: (level: SuggestVocabularyBand) => void;
}

function SuggestVocabularyPopover({ repharseLoading, onSuggestVoca }: ISuggestVocabularyPopoverProps) {
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);
  const { data: dataQuota } = useGetQuota({});
  const [level, setLevel] = useState(SuggestVocabularyBand["7_0"]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [suggestModal, setSuggestModal] = useState<null | string>(null);
  const { data: listEnergyPrice } = useGetListEnergyPrice({});

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setLevel(event.target.value as SuggestVocabularyBand);
  };

  const onClickSubmit = () => {
    // onSuggestVoca(level);
    handleClose();
    if (level) {
      onSuggestVoca(level);
    }
  };

  return (
    <>
      <div>
        <Tooltip
          title={
            false || dataQuota?.week_count === dataQuota?.week_max
              ? "Your quote is limited. Please comeback next week "
              : "Click to generate vocabulary with band ielts"
          }
          sx={{
            fontSize: 20,
          }}
          aria-setsize={20}
        >
          <span>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClick}
              sx={{ minWidth: "100px", backgroundColor: "#5c3fb3", textTransform: "none" }}
              disabled={
                // !editCustomAnswerContent.data || repharseLoading ||
                dataQuota?.week_count === dataQuota?.week_max
              }
              aria-describedby={id}
            >
              <GTranslateIcon sx={{ marginRight: "4px" }} />
              {repharseLoading ? <CircularProgress size={20} /> : "Vocabulary"}
              <Typography sx={{ fontWeight: "bold", display: "flex", alignItems: "center", marginLeft: "5px" }}>
                ({listEnergyPrice?.types?.[LLMTypes.suggest_vocabulary]}{" "}
                <BoltIcon
                  sx={{
                    marginLeft: "2px",
                    fontSize: "18px",
                  }}
                  htmlColor="#fff31b"
                />
                )
              </Typography>
            </Button>
          </span>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Typography sx={{}}>Please choose IELTS level</Typography>
            <Box sx={{ display: "flex", gap: "10px", marginTop: "10px", justifyContent: "space-between" }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={level}
                onChange={handleChange}
                sx={{
                  height: "48px",
                }}
              >
                {Object.values(SuggestVocabularyBand).map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              <IconButton
                sx={{
                  height: "48px",
                  width: "48px",
                }}
                onClick={onClickSubmit}
              >
                <SendIcon color="secondary" />
              </IconButton>
            </Box>
          </Box>
        </Popover>
      </div>
      {/* {showModal} */}
    </>
  );
}

export { SuggestVocabularyPopover };
