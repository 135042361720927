import { ThemeProvider } from "@mui/material";
import "./App.css";
import { AllPages } from "./routes";
import "./scss/index.scss";
import { getStore } from "./store-redux";
import { Provider } from "react-redux";
import { SnackbarMessage } from "./components/SnackbarMessage";
import Authorization from "./components/Authorization";
import QueryProvider from "./providers/QueryClientProvider";
import createTheme from "@mui/material/styles/createTheme";
import ChatwootWidget from "./pages/ChatBot/ChatwootWidget";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2A9D8F",
    },
    secondary: {
      main: "#000000",
    },
  },
  typography: {
    fontFamily: ["Nunito", "sans-serif"].join(","),
  },
});

function App() {
  return (
    <Provider store={getStore({})}>
      <ThemeProvider theme={theme}>
        <QueryProvider>
          <SnackbarMessage />
          <Authorization>
            <AllPages />
          </Authorization>
          <ChatwootWidget />
        </QueryProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
