import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from "@mui/material";
import { Question } from "../LearnVocaByQuestion";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { ENDPOINTS, getApiUrl, IItems, Requests, ScoreBand } from "@/common";
import { extractContent, refinePrompt } from "@/helpers";
import { showMessage } from "@/store-redux";
import { useDispatch } from "react-redux";
import { Delete, Edit } from "@mui/icons-material";
import { VocaType } from "@/constants";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const listVocaType = [
  VocaType.noun,
  VocaType.verb,
  VocaType.adjective,
  VocaType.adverb,
  VocaType.phrases,
  VocaType.collocation,
  VocaType.idiom,
];

const dataVocaInit = {
  [VocaType.noun]: [],
  [VocaType.verb]: [],
  [VocaType.adjective]: [],
  [VocaType.adverb]: [],
  [VocaType.phrases]: [],
  [VocaType.collocation]: [],
  [VocaType.idiom]: [],
};
const GenVocaByBand = ({ band, question }: { band: ScoreBand; question: Question | null }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [value, setValue] = React.useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [dataVocaQuestion, setDataVocaQuestion] = useState<{ [key: string]: any }>(dataVocaInit);
  const vocaTypeActive = listVocaType[value];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (isLoading) return;
    setValue(newValue);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const dataRes = await Requests.get(
          getApiUrl(ENDPOINTS.GET_VOCA_QUESTION.replace(":questionId", question?._id ?? "") + "?band=" + band)
        );
        if (dataRes) {
          setDataVocaQuestion({
            [VocaType.noun]: Array.isArray(dataRes[VocaType.noun]) ? dataRes[VocaType.noun] : [],
            [VocaType.verb]: Array.isArray(dataRes[VocaType.verb]) ? dataRes[VocaType.verb] : [],
            [VocaType.adjective]: Array.isArray(dataRes[VocaType.adjective]) ? dataRes[VocaType.adjective] : [],
            [VocaType.adverb]: Array.isArray(dataRes[VocaType.adverb]) ? dataRes[VocaType.adverb] : [],
            [VocaType.phrases]: Array.isArray(dataRes[VocaType.phrases]) ? dataRes[VocaType.phrases] : [],
            [VocaType.collocation]: Array.isArray(dataRes[VocaType.collocation]) ? dataRes[VocaType.collocation] : [],
            [VocaType.idiom]: Array.isArray(dataRes[VocaType.idiom]) ? dataRes[VocaType.idiom] : [],
          });
        } else {
          setDataVocaQuestion(dataVocaInit);
        }
      } catch {
        setDataVocaQuestion(dataVocaInit);
      }
      //   if (dataRes.data) set(dataRes.data);
    };
    getData();
  }, [band]);

  const onGenVoca = async () => {
    setIsLoading(true);
    const apiUrl = getApiUrl(ENDPOINTS.GEN_VOCA_BY_BAND);
    try {
      const data = await Requests.post(apiUrl, {
        question: refinePrompt(extractContent(question?.name ?? "")),
        vocaType: vocaTypeActive,
        band,
        nbWords: 10,
      });
      if (Array.isArray(data)) {
        setDataVocaQuestion((prev) => ({
          ...prev,
          [vocaTypeActive]: [...data, ...prev[vocaTypeActive]],
        }));
      }
    } catch (e) {
      console.log("e", e);
    }
    setIsLoading(false);
  };

  const handleInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const data = dataVocaQuestion[vocaTypeActive];
    const updatedData = data.map((item: any, idx: number) => (idx === index ? { ...item, [name]: value } : item));
    setDataVocaQuestion((prev) => ({
      ...prev,
      [vocaTypeActive]: updatedData,
    }));
  };
  const handleDelete = (index: number) => {
    const data = dataVocaQuestion[vocaTypeActive];
    const updatedData = data.filter((_, idx) => idx !== index);
    setDataVocaQuestion((prev) => ({
      ...prev,
      [vocaTypeActive]: updatedData,
    }));
  };

  const onSave = async () => {
    setIsSaving(true);
    const apiUrl = getApiUrl(ENDPOINTS.SAVE_VOCA_QUESTION);
    try {
      const data = await Requests.post(apiUrl, {
        question: question?._id,
        band,
        ...dataVocaQuestion,
      });
      dispatch(showMessage("Save voca question success", "success"));
    } catch (e) {
      console.log("e", e);
    }
    setIsSaving(false);
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", alignItems: "flex-end", padding: "8px" }}>
        <Tabs
          sx={{
            flex: 1,
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {listVocaType.map((vocaType, index: number) => (
            <Tab
              sx={{
                textTransform: "capitalize",
              }}
              label={vocaType}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        <Button
          sx={{
            marginRight: "8px",
            textTransform: "none",
            "& .MuiCircularProgress-root": {
              color: "#fff",
            },
          }}
          variant={!isEditMode ? "contained" : undefined}
          onClick={() => {
            setIsEditMode((prev) => !prev);
          }}
        >
          <Edit />
          {isEditMode ? "Unedit" : "Edit"} field
        </Button>
        <Button
          sx={{
            textTransform: "none",
            "& .MuiCircularProgress-root": {
              color: "#fff",
            },
          }}
          variant="contained"
          onClick={() => onSave()}
          disabled={isSaving || isLoading}
        >
          {isSaving && <CircularProgress size={20} />}
          Save
        </Button>
      </Box>
      <Box
        sx={{
          padding: "8px",
        }}
      >
        <Button
          sx={{
            textTransform: "none",
            "& .MuiCircularProgress-root": {
              color: "#fff",
            },
          }}
          variant="contained"
          onClick={() => onGenVoca()}
          disabled={isSaving || isLoading}
        >
          {isLoading && <CircularProgress size={20} />}
          <TipsAndUpdatesIcon />
          Gen voca {band}
        </Button>
        {Array.isArray(dataVocaQuestion[vocaTypeActive]) && (
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "calc(100vh - 240px)",
              overflowY: "auto",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      fontWeight: "bold",
                    },
                  }}
                >
                  <TableCell>Text</TableCell>
                  <TableCell>Meaning (VI)</TableCell>
                  <TableCell>Example</TableCell>
                  {isEditMode && <TableCell></TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataVocaQuestion[vocaTypeActive].map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "& input": {
                        padding: "8px",
                      },
                      "& td": {
                        padding: "8px",
                      },
                    }}
                  >
                    <TableCell>
                      {isEditMode ? (
                        <TextField
                          name="text"
                          value={item.text}
                          onChange={(e) => handleInputChange(e, index)}
                          fullWidth
                        />
                      ) : (
                        item.text
                      )}
                    </TableCell>
                    <TableCell>
                      {isEditMode ? (
                        <TextField
                          name="meaning_vi"
                          value={item.meaning_vi}
                          onChange={(e) => handleInputChange(e, index)}
                          fullWidth
                        />
                      ) : (
                        item.meaning_vi
                      )}
                    </TableCell>
                    <TableCell>
                      {isEditMode ? (
                        <TextField
                          name="example"
                          value={item.example}
                          onChange={(e) => handleInputChange(e, index)}
                          fullWidth
                        />
                      ) : (
                        item.example
                      )}
                    </TableCell>
                    {isEditMode && (
                      <TableCell>
                        <IconButton onClick={() => handleDelete(index)} color="secondary">
                          <Delete />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  );
};
export default GenVocaByBand;
