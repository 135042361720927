import { ELevel } from "@/common";
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { TypeAnimation } from "react-type-animation";
import { ETypeQuestion } from "./interface";

interface IChooseLevelProps {
  nextStep: (level: ELevel) => void;
  setTypeQuestion: (type: ETypeQuestion) => void;
  typeQuestion: ETypeQuestion;
}

function ChooseLevel({ nextStep, setTypeQuestion, typeQuestion }: IChooseLevelProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeQuestion((event.target as HTMLInputElement).value as ETypeQuestion);
  };

  return (
    <Box
      sx={() => ({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& h2": {
          textAlign: "center",
          fontSize: "30px",
          marginBottom: "8vh",
          marginInline: "32px",
          height: "90px",
        },
        "& img": {
          width: "40vw",
          marginBlock: "15px",
        },
      })}
    >
      {/* <img src="/images/coming-soon.png" />
      <Typography component={"h2"}>
        <span style={{ color: "gray", fontSize: 20 }}>
          We will notify you once this feature is ready
        </span>
      </Typography> */}
      <TypeAnimation
        sequence={["Welcome to the challenge \n Please choose your level!"]}
        wrapper="h2"
        speed={50}
        style={{
          whiteSpace: "pre-line",
        }}
        cursor={false}
      />
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={typeQuestion}
          onChange={handleChange}
          sx={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "15px",
          }}
        >
          <FormControlLabel value="choose" control={<Radio />} label="Choose with topic" />
          <FormControlLabel value="random" control={<Radio />} label="Random question" />
        </RadioGroup>
      </FormControl>
      <Box
        sx={() => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
          width: "100%",
          "& button": {
            width: "500px",
            borderRadius: "8px",
            height: 56,
            fontSize: "16px",
            fontWeight: "400",
            textTransform: "capitalize",
            "&.active": {
              fontWeight: "bold",
            },
          },
        })}
      >
        {Object.values(ELevel).map((level, index) => (
          <Button key={index} variant="contained" color="secondary" onClick={() => nextStep(level)}>
            {level.slice(0, level.length - 1) + " " + level.charAt(level.length - 1)}
          </Button>
        ))}
      </Box>
    </Box>
  );
}

export default ChooseLevel;
