import { AiBoxTab, EChangeLengthAnswer, ScoreBand } from "@/common/types";
import {
  useGeneratorConnector,
  useGrammarAnalysis,
  useRephraseAnswer,
  useWordLimitAnswer,
} from "@/services-react-query/queries";
import { IApplicationState, getRemainingEnergy } from "@/store-redux";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddConnector from "./AddConnector";
import { GrammarAnalysisModal } from "./GrammarAnalysisModal";
import { RepharseAnswerPopover } from "./RepharseAnswerPopover";
import { WordLimitAnswerPopover } from "./WordLimitAnswerPopover";

interface ITabAssistantAIProps {
  isPartTwo?: boolean;
  contentGeneratorConnector: string;
  setContentGeneratorConnector: React.Dispatch<React.SetStateAction<string>>;
  setBoxTabActive: React.Dispatch<React.SetStateAction<AiBoxTab | null>>;
  question?: string;
}

function TabAssistantAI(props: ITabAssistantAIProps) {
  const { setContentGeneratorConnector, setBoxTabActive } = props;
  const dispatch = useDispatch();
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);
  const isHasContentEdit = Boolean(editCustomAnswerContent?.data && editCustomAnswerContent?.data?.trim());

  const { mutate: generateConnector, isLoading: generateConnectorLoading } = useGeneratorConnector({
    onSuccess(data) {
      setContentGeneratorConnector(data);
      // refetch();
      dispatch(getRemainingEnergy());
    },
  });
  const { mutate: repharse, isLoading: repharseLoading } = useRephraseAnswer({
    onSuccess(data) {
      setContentGeneratorConnector(data);
      // refetch();
      dispatch(getRemainingEnergy());
    },
  });
  const { mutate: wordLimitAnswer, isLoading: wordLimitLoading } = useWordLimitAnswer({
    onSuccess(data) {
      setContentGeneratorConnector(data);
      // refetch();
      dispatch(getRemainingEnergy());
    },
  });

  const [dataGrammarAnalysis, setDataGrammarAnalysis] = useState({
    open: false,
    data: "",
  });

  const { mutate: grammarAnalysis, isLoading: grammarAnalysisLoading } = useGrammarAnalysis({
    onSuccess(data) {
      setDataGrammarAnalysis({
        open: true,
        data,
      });
      // refetch();
      dispatch(getRemainingEnergy());
    },
  });

  const onGenerateConnector = () => {
    if (editCustomAnswerContent.data?.trim()) {
      generateConnector({ sentence: editCustomAnswerContent.data, question: props.question ?? "" });
      setBoxTabActive(AiBoxTab.contentGeneratorConnector);
    }
  };

  const onRephraseAnswer = (level: string) => {
    repharse({ sentence: editCustomAnswerContent.data, band: level as ScoreBand, question: props.question ?? "" });
    setBoxTabActive(AiBoxTab.contentGeneratorConnector);
  };
  const onWordLimit = (type: EChangeLengthAnswer) => {
    wordLimitAnswer({ sentence: editCustomAnswerContent.data, expectedLength: type, question: props.question ?? "" });
    setBoxTabActive(AiBoxTab.contentGeneratorConnector);
  };

  if (generateConnectorLoading || repharseLoading || wordLimitLoading || grammarAnalysisLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            maxWidth: "500px",
            "& div": {
              display: "flex",
              justifyContent: "center",
            },
          }}
        >
          <Box>
            <img src="/images/robot-writing.gif" />
          </Box>
          <Typography
            component={"span"}
            sx={{
              marginTop: "16px",
              marginBottom: "16px",
              color: "info.main",
            }}
          >
            Your request is being processed by the system. Please wait a moment...
          </Typography>
          <LinearProgress color="info" />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: "8px",
        flexWrap: "wrap",
        "& .card-ai": {
          // height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          fontSize: "16px",
          cursor: "pointer",
          "&>div": {
            display: "flex",
            gap: "4px",
            padding: "8px",
          },
          ...(isHasContentEdit ? {} : { backgroundColor: "#e1e1e1", cursor: "default", pointerEvents: "none" }),
        },
      }}
    >
      {!props.isPartTwo && <AddConnector onGenerateConnector={onGenerateConnector} />}
      <RepharseAnswerPopover onRephraseAnswer={onRephraseAnswer} />
      <WordLimitAnswerPopover onWordLimit={onWordLimit} />
      <GrammarAnalysisModal
        grammarAnalysis={grammarAnalysis}
        setDataGrammarAnalysis={setDataGrammarAnalysis}
        dataGrammarAnalysis={dataGrammarAnalysis}
      />
    </Box>
  );
}

export default TabAssistantAI;
