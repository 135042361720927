import { cleanQuestion, onPlayReadStr } from "@/helpers";
import { IApplicationState } from "@/store-redux";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

interface IProps {
  onNext: () => void;
}

function RobotReadingQuestion({ onNext }: IProps) {
  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);
  const question = cleanQuestion(detailQuestion.data?.name ?? "");
  const readRef = useRef(false);
  useEffect(() => {
    !readRef.current && onPlayReadStr(question, onNext);
    readRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default RobotReadingQuestion;
