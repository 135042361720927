import { ENDPOINTS, Requests, getApiUrl } from "@/common";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDispatch } from "react-redux";
import { showMessage } from "@/store-redux";

interface IAspectItemProps {
  item: any;
}

const AspectItem = (props: IAspectItemProps) => {
  const { item } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [structure, setStructure] = useState(null);
  const dispatch = useDispatch();

  const onCopyExample = (example: any) => {
    navigator.clipboard.writeText(example);
    dispatch(showMessage("Copy example successfully!", "success"));
  };

  return (
    <>
      {structure && (
        <Box
          sx={{
            position: "absolute",
            top: "60px",
            bottom: "10px",
            left: "10px",
            right: "10px",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              padding: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  textTransform: "initial",
                  fontWeight: "bold",
                }}
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  setStructure(null);
                }}
              >
                back
              </Button>
              <Typography
                sx={{
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              >
                {item.aspect}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <DoubleArrowIcon />
              <Typography
                sx={{
                  marginLeft: "5px",
                }}
              >
                {structure.name}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              height: "calc(100% - 80px)",
              overflowY: "auto",
            }}
          >
            {structure.examples.map((example, idx) => {
              return (
                <Box
                  sx={{
                    padding: "10px",
                    borderBottom: "1px solid #ccc",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  key={idx}
                >
                  <Typography>{example}</Typography>
                  <IconButton
                    onClick={() => {
                      onCopyExample(example);
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
      <Box>
        <Box
          sx={{
            cursor: "pointer",
            padding: "10px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => setIsExpanded((prev) => !prev)}
        >
          <Typography
            sx={{
              flex: "1",
            }}
          >
            {item.aspect}
          </Typography>
          <Typography
            sx={{
              backgroundColor: "pink",
              padding: "2px 10px",
              borderRadius: "50%",
              fontWeight: "700",
              fontSize: "18px",
            }}
          >
            {item.structures.length}
          </Typography>
        </Box>
        {isExpanded && (
          <Box
            sx={{
              borderLeft: "2px solid #264653",
              marginLeft: "20px",
              marginBottom: "10px",
            }}
          >
            {item.structures.map((structure) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <Box
                    sx={{
                      height: "2px",
                      width: "40px",
                      backgroundColor: "#264653",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      cursor: "pointer",
                      paddingTop: "20px",
                      marginBottom: "-10px",
                      paddingLeft: "10px",
                      fontSize: "15px",
                      display: "flex",
                      // alignItems: "center",
                      "&:hover": {
                        color: "#ccc",
                      },
                    }}
                    onClick={() => setStructure(structure)}
                  >
                    <Typography>{structure.name}</Typography>
                    <Typography
                      sx={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      ({structure.examples.length})
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
};

const StructureSuggestBtn = () => {
  const [openSuggest, setOpenSuggest] = useState(false);
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    if (openSuggest && !data) {
      getData();
    }
  }, [openSuggest]);
  const getData = async () => {
    const data = await Requests.get(getApiUrl(ENDPOINTS.GET_STRUCTURE_CMS));
    if (data) {
      setData(data);
    }
  };
  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        sx={{
          textTransform: "initial",
          backgroundColor: "#F99417",
        }}
        startIcon={<AccountTreeIcon />}
        onClick={() => {
          setOpenSuggest(true);
        }}
      >
        Structure
      </Button>
      <Modal
        open={openSuggest}
        onClose={() => {
          setOpenSuggest(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            padding: "10px",
            borderRadius: "8px",
            maxHeight: "calc(100vh - 40px)",
            maxWidth: "calc(100vw - 40px)",
            width: "1000px",
            height: "800px",
          }}
        >
          <Box
            sx={{
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <AccountTreeIcon />
              <Typography
                sx={{
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              >
                Structure Suggestion
              </Typography>
            </Box>
            <IconButton
              onClick={() => {
                setOpenSuggest(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              overflowY: "auto",
              height: "calc(100% - 50px)",
            }}
          >
            {!data ? (
              <></>
            ) : (
              <>
                {data?.map((item: any, idx: number) => {
                  return <AspectItem item={item} key={idx} />;
                })}
              </>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default StructureSuggestBtn;
