import { Box, Button, Typography } from "@mui/material";

const listSpeed = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

interface IProps {
  setSpeed: React.Dispatch<React.SetStateAction<number>>;
  speed: number;
  onNext: () => void;
}

function ChooseSpeed({ setSpeed, speed, onNext }: IProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography>Please choose speed</Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          cursor: "pointer",
          gap: "15px",
          marginTop: "16px",
          "& div": {
            padding: "5px 8px",
          },
        }}
      >
        {listSpeed.map((s) => (
          <Box
            key={s}
            sx={{
              ...(s === speed
                ? {
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    borderRadius: "8px",
                  }
                : {}),
            }}
            onClick={() => setSpeed(s)}
          >
            <Typography sx={{ fontWeight: "bold" }}>{s}</Typography>
          </Box>
        ))}
      </Box>
      <Button
        variant="contained"
        onClick={onNext}
        sx={{
          minWidth: "68px",
          height: "40px",
          padding: "6px 16px",
          borderRadius: "4px",
          fontSize: "16px",
          fontWeight: 700,
          textTransform: "none",
          marginTop: "16px",
        }}
      >
        Next
      </Button>
    </Box>
  );
}

export default ChooseSpeed;
