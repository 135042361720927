import React, { useState } from "react";
import { FillBlanksExerciseData, IUserAnswers } from "../types/exercise.types";

interface Props {
  data: FillBlanksExerciseData;
  isSubmitted?: boolean;
  setUserAnswers: React.Dispatch<React.SetStateAction<IUserAnswers>>;
}

const FillBlanksExercise: React.FC<Props> = ({ data, isSubmitted, setUserAnswers }) => {
  const [answers, setAnswers] = useState<Record<string, string>>({});

  const handleChange = (sentenceIndex: number, blankIndex: number, value: string): void => {
    const newAnswers = {
      ...answers,
      [`${sentenceIndex}-${blankIndex}`]: value.toLowerCase(),
    };
    setAnswers(newAnswers);
    setUserAnswers((prev: IUserAnswers) => {
      const updatedFillBlanks = {
        ...prev.fillBlanks,
        ...newAnswers,
      };
      return {
        ...prev,
        fillBlanks: updatedFillBlanks,
      };
    });
  };

  return (
    <div className="exercise-section fill-blanks">
      <h2>Fill in the Blanks</h2>
      <p>{data.instructions}</p>

      {data?.word_bank ? (
        <div className="word-bank">
          <strong>Word Bank:</strong> {data.word_bank?.join(", ")}
        </div>
      ) : null}

      <div className="sentences">
        {data.sentences.map((sentence, sentenceIndex) => {
          const parts = sentence.sentence.split("_____");
          return (
            <div key={sentenceIndex} className="sentence">
              {parts.map((part, index) => (
                <React.Fragment key={index}>
                  {part}
                  {index < parts.length - 1 && (
                    <input
                      type="text"
                      onChange={(e) => handleChange(sentenceIndex, index, e.target.value)}
                      value={answers[`${sentenceIndex}-${index}`] || ""}
                      style={{
                        backgroundColor: isSubmitted
                          ? sentence.answers.includes(answers[`${sentenceIndex}-${index}`]?.toLowerCase())
                            ? "#e8f5e9" // green for correct
                            : "#ffebee" // red for incorrect
                          : "white",
                      }}
                      disabled={isSubmitted}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FillBlanksExercise;
