export const onPlayReadStr = (str: string, onNext?: () => void) => {
  try {
    if ("speechSynthesis" in window) {
      const synth = window.speechSynthesis;
      const utterance = new SpeechSynthesisUtterance(str);
      utterance.lang = "en-US";
      synth.speak(utterance);

      utterance.onend = function () {
        onNext && onNext?.();
      };
    }
  } catch (error) {
    console.log("onPlayReadStr", error);
  }
};
