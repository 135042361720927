import { EChangeLengthAnswer } from "@/common";
import { useGetListEnergyPrice } from "@/services-react-query/queries";
import RepeatIcon from "@mui/icons-material/Repeat";
import { Box, Button, Card, Popover, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import BoltIcon from "@mui/icons-material/Bolt";
import { LLMTypes } from "@/constants";

interface IWordLimitAnswerPopoverProps {
  onWordLimit: (type: EChangeLengthAnswer) => void;
}

function WordLimitAnswerPopover({ onWordLimit }: IWordLimitAnswerPopoverProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: listEnergyPrice } = useGetListEnergyPrice({});

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClickSubmit = (type: EChangeLengthAnswer) => {
    handleClose();
    onWordLimit(type);
  };

  return (
    <div>
      <Tooltip
        title={"Click to adjust word limit"}
        sx={{
          fontSize: 20,
        }}
        aria-setsize={20}
      >
        <Card
          className={clsx(["card-ai"])}
          onClick={handleClick}
          sx={{
            backgroundColor: "#e05b22",
          }}
        >
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <RepeatIcon />
              Adjust word limit
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "bold" }}>({listEnergyPrice?.types?.[LLMTypes.adjust_word_limit]} </Typography>
              <BoltIcon
                sx={{
                  marginLeft: "2px",
                  fontSize: "18px",
                }}
                htmlColor="#fff31b"
              />
              )
            </Box>
          </Box>
        </Card>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2, display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "center" }}>
          <Typography sx={{}}>Please choose option that you want</Typography>
          <Box
            sx={{
              marginBlock: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              "& button": {
                textTransform: "none",
              },
            }}
          >
            <Button onClick={() => onClickSubmit(EChangeLengthAnswer.shorten)} variant="text" color="success">
              Shorten
            </Button>
            -
            <Button onClick={() => onClickSubmit(EChangeLengthAnswer.lengthen)} variant="text" color="warning">
              Lengthen
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
}

export { WordLimitAnswerPopover };
