import LayoutTab from "@/components/LayoutTab";
import NoDataComponent from "@/components/NoDataComponent";
import { ROUTES } from "@/constants";
import { cleanQuestion, extractContent, getUrlServer, removeLeadingNumbersAndPeriods } from "@/helpers";
import { ETypeItem, getListQuestions, getListTopics, IApplicationState, IItems, parseParamUrl } from "@/store-redux";
import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import ChooseQuestionTypeTab from "./ChooseQuestionTypeTab";

function TopicQuestionTypePage() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const typeQuestion = searchParams.get("type-question");
  const [tabValue, setTabValue] = useState(1);
  const listTopics = useSelector((state: IApplicationState) => state.listTopics);
  const [topicSelected, setTopicSelected] = useState<string>("");
  const listQuestions = useSelector((state: IApplicationState) => state.listQuestions);

  const handleGetListTopics = () => {
    dispatch(
      getListTopics({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
        type: ETypeItem.topic,
      })
    );
  };
  const handleGetListQuestions = (topicId: string) => {
    dispatch(
      getListQuestions({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
        type: ETypeItem.question,
        parentId: topicId,
      })
    );
  };

  const listQuestionWithPart = useMemo(() => {
    if (tabValue === 2) return listQuestions?.data ?? [];
    if (listQuestions?.data?.length) {
      const dataTemp: IItems[] = [];
      listQuestions.data?.forEach((item) => {
        const prefixMatch = extractContent(item.name).match(/^(P\d+)/);
        const prefix = prefixMatch?.[1];
        if (prefix === typeQuestion) {
          dataTemp.push(item);
        }
      });
      return dataTemp;
    }
    return [];
  }, [listQuestions.data, typeQuestion, tabValue]);

  const onClickTopic = (item: IItems) => {
    setTopicSelected(item._id);
    handleGetListQuestions(item._id);
  };

  const handleGetListQuestionsByQuestionType = (questionTypeId: string) => {
    dispatch(
      getListQuestions({
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: "createdAt",
        type: ETypeItem.question,
        questionTypeId: questionTypeId,
        parentId: "*",
      })
    );
  };

  const renderTitleTopic = useMemo(() => {
    if (tabValue === 1) {
      const findTopic = listTopics?.data?.find((item) => item._id === topicSelected);
      return removeLeadingNumbersAndPeriods(extractContent(findTopic?.name ?? ""));
    }
    if (tabValue === 2) {
      return "";
    }
  }, [listTopics?.data, topicSelected, tabValue]);

  useEffect(() => {
    if (listTopics.data?.length) return;
    handleGetListTopics();
  }, []);

  return (
    <LayoutTab
      tabValue={tabValue}
      setTabValue={(id) => setTabValue(Number(id))}
      listTab={[
        {
          id: 1,
          label: "Topic",
        },
        {
          id: 2,
          label: "Question type",
        },
      ]}
      title={"Topic & Question"}
      subTitle={"Choose topic and question to practice"}
      breadcrumbs={[
        {
          text: "Learn",
          link: ROUTES.LEARN,
        },
        {
          text: `Topic & Question`,
        },
      ]}
    >
      <Box
        sx={{
          display: "flex",
          "& >div:first-of-type": {
            flex: 8,
            scrollbarWidth: "none" /* Firefox */,
            msOverflowStyle: "none" /* IE and Edge */,
            "& ::-webkit-scrollbar": {
              display: "none",
            },
          },
          "& >div:last-of-type": {
            flex: 4,
          },
        }}
      >
        {tabValue === 1 && (
          <Box
            sx={{
              "& .box-container": {
                overflowY: "auto",
                marginTop: "10px",
                paddingRight: "5px",
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: "20px",
                height: "calc(100vh - 182px)",
                paddingBottom: '16px',
                [theme.breakpoints.up("md")]: {
                  gridTemplateColumns: "1fr 1fr",
                },
                [theme.breakpoints.up("lg")]: {
                  gridTemplateColumns: "1fr 1fr 1fr",
                },
                " .title": {
                  fontSize: "14px",
                  marginBottom: "8px",
                },
                " .image": {
                  height: "100px",
                  width: "fit-content",
                  //   width: "250px",
                  objectFit: "contain",
                  borderRadius: "8px",
                  margin: "auto",
                },
              },
              "& .box-item": {
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                border: "1px solid #D9D9D9",
                borderRadius: "8px",
                "&.active": {
                  border: `1px solid ${theme.palette.primary.main}`,
                  " .title": {
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                  },
                },
                "&:hover": {
                  backgroundColor: "#ECEAEA",
                  cursor: "pointer",
                  border: `1px solid ${theme.palette.primary.main}`,
                  " .title": {
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                  },
                },
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                marginTop: "8px",
              }}
            >
              Choose topic
            </Typography>
            {listTopics.isFetching ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  paddingRight: "16px",
                  "&>span": {
                    width: "100%",
                  },
                }}
              >
                <Skeleton animation="wave" sx={{ height: "50px" }} />
                <Skeleton animation="wave" sx={{ height: "50px" }} />
                <Skeleton animation="wave" sx={{ height: "50px" }} />
                <Skeleton animation="wave" sx={{ height: "50px" }} />
                <Skeleton animation="wave" sx={{ height: "50px" }} />
                <Skeleton animation="wave" sx={{ height: "50px" }} />
                <Skeleton animation="wave" sx={{ height: "50px" }} />
              </Box>
            ) : (
              <Box className="box-container">
                {listTopics.data?.length ? (
                  listTopics.data?.map((topic) => (
                    <Box
                      key={topic._id}
                      className={clsx("box-item", topicSelected === topic._id ? "active" : "")}
                      onClick={() => onClickTopic(topic)}
                    >
                      <Box className="title">{removeLeadingNumbersAndPeriods(extractContent(topic.name))}</Box>
                      {topic.imageUrl ? (
                        <img className="image" src={getUrlServer(topic.imageUrl ?? "")} loading="lazy" />
                      ) : (
                        <Box className="image" />
                      )}
                    </Box>
                  ))
                ) : (
                  <NoDataComponent />
                )}
              </Box>
            )}
          </Box>
        )}
        {tabValue === 2 && (
          <ChooseQuestionTypeTab
            chooseQuestionType={(questionTypeId: string | null) => {
              if (questionTypeId) {
                handleGetListQuestionsByQuestionType(questionTypeId);
              }
            }}
          />
        )}
        {
          <Box
            className="list-question"
            sx={{
              minWidth: "320px",
              borderLeft: "1px solid #D9D9D9",
              "& .box-item": {
                padding: "12px 4px",
                borderBottom: "1px solid #D9D9D9",
                cursor: "pointer",
                marginLeft: "4px",
                "& .title": {
                  fontSize: "14px",
                },
                "&:last-type": {
                  borderBottom: "none",
                },
              },
            }}
          >
            <Box
              sx={{
                borderBottom: "1px solid #D9D9D9",
                padding: "16px 20px 16px 20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  "& p": {
                    fontSize: "16px",
                    fontWeight: "600",
                  },
                }}
              >
                <Typography>Question</Typography>
                <Box
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    height: "20px",
                    width: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {listQuestionWithPart?.length ?? 0}
                </Box>
              </Box>
              {!!renderTitleTopic && (
                <Typography
                  sx={{
                    color: "#6A6969",
                    fontSize: "14px",
                  }}
                >
                  Choose question (topic “{renderTitleTopic}")
                </Typography>
              )}
            </Box>
            {listQuestions.isFetching ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  paddingInline: "16px",
                  marginTop: "16px",
                }}
              >
                <Skeleton animation="wave" width={"100%"} />
                <Skeleton animation="wave" width={"100%"} />
                <Skeleton animation="wave" width={"100%"} />
                <Skeleton animation="wave" width={"100%"} />
                <Skeleton animation="wave" width={"100%"} />
                <Skeleton animation="wave" width={"100%"} />
                <Skeleton animation="wave" width={"100%"} />
              </Box>
            ) : listQuestionWithPart?.length > 0 ? (
              listQuestionWithPart?.map((question: IItems) => (
                <Box
                  key={question._id}
                  onClick={() => {
                    const url = parseParamUrl(
                      ROUTES.LEARN_QUESTION_PAGE.replace(":questionId", question._id),
                      undefined,
                      {
                        "type-question": typeQuestion,
                      }
                    );
                    navigate(url);
                  }}
                  className="box-item"
                >
                  <Box className="title">{cleanQuestion(question.name)}</Box>
                  <Box className="description" dangerouslySetInnerHTML={{ __html: question.description || "" }} />
                </Box>
              ))
            ) : (
              <NoDataComponent />
            )}
          </Box>
        }
      </Box>
    </LayoutTab>
  );
}

export default TopicQuestionTypePage;
