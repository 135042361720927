import { Box, Button, CircularProgress, List, ListItem, ListItemText, Typography } from "@mui/material";
import SubmitHomework from "../SubmitHomework";
import { useEffect, useState } from "react";
import { ENDPOINTS, getApiUrl, Requests } from "@/common";

interface HomeworkPageProps {
  lessonId: string | null;
}
const HomeworkPage = ({ lessonId }: HomeworkPageProps) => {
  const [homeworkList, setHomeworkList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // Function to fetch homework submissions for a specific lesson
  const fetchHomework = async () => {
    setIsLoading(true);
    try {
      const dataRes = await Requests.get(getApiUrl(ENDPOINTS.GET_HOMEWORK_BY_LESSON.replace(":id", lessonId)));
      if (Array.isArray(dataRes)) setHomeworkList(dataRes);
    } catch (error) {
      console.error("Failed to fetch homework:", error);
    }
    setIsLoading(false);
  };

  // Fetch homework submissions when the component mounts or lessonId changes
  useEffect(() => {
    fetchHomework();
  }, [lessonId]);
  return (
    <Box>
      <SubmitHomework lessonId={lessonId} onRefresh={() => fetchHomework()} />
      <Box>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <List>
            {homeworkList.length > 0 ? (
              homeworkList.map((homework) => (
                <ListItem key={homework._id}>
                  <ListItemText primary={homework.text ? `Text: ${homework.text}` : "File submitted"} />
                  {homework.fileUrl && (
                    <Button
                      variant="contained"
                      color="primary"
                      href={homework.fileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        textTransform: "none",
                      }}
                    >
                      Download File
                    </Button>
                  )}
                </ListItem>
              ))
            ) : (
              <Typography variant="body1">No homework submissions yet.</Typography>
            )}
          </List>
        )}
      </Box>
    </Box>
  );
};

export default HomeworkPage;
