import React, { useState } from "react";
import { IUserAnswers, SentenceFormationExerciseData } from "../types/exercise.types";

interface Props {
  data: SentenceFormationExerciseData;
  isSubmitted?: boolean;
  setUserAnswers: React.Dispatch<React.SetStateAction<IUserAnswers>>;
}

const SentenceFormationExercise: React.FC<Props> = ({ data, isSubmitted, setUserAnswers }) => {
  const [arrangedSentences, setArrangedSentences] = useState<Record<number, string[]>>({});

  const handleWordClick = (sentenceIndex: number, word: string): void => {
    setArrangedSentences((prev) => {
      const currentSentence = prev[sentenceIndex] || [];
      const newSentence = [...currentSentence, word];
      setUserAnswers((prevAnswers) => ({
        ...prevAnswers,
        sentenceFormation: {
          ...prevAnswers.sentenceFormation,
          [sentenceIndex]: newSentence,
        },
      }));
      return {
        ...prev,
        [sentenceIndex]: newSentence,
      };
    });
  };

  const removeWord = (sentenceIndex: number, wordIndex: number): void => {
    setArrangedSentences((prev) => {
      const newSentence = [...(prev[sentenceIndex] || [])];
      newSentence.splice(wordIndex, 1);
      setUserAnswers((prevAnswers) => ({
        ...prevAnswers,
        sentenceFormation: {
          ...prevAnswers.sentenceFormation,
          [sentenceIndex]: newSentence,
        },
      }));
      return {
        ...prev,
        [sentenceIndex]: newSentence,
      };
    });
  };

  return (
    <div className="exercise-section">
      <h2>Sentence Formation</h2>
      <p>{data.instructions}</p>

      {data.sentences.map((sentence, sentenceIndex) => (
        <div key={sentenceIndex} className="sentence-formation">
          <div className="word-bank">
            {sentence.words.map((word, wordIndex) => (
              <button
                key={wordIndex}
                onClick={() => handleWordClick(sentenceIndex, word)}
                disabled={(arrangedSentences[sentenceIndex] || []).includes(word)}
              >
                {word}
              </button>
            ))}
          </div>

          <div className="arranged-sentence">
            {(arrangedSentences[sentenceIndex] || []).map((word, wordIndex) => (
              <span key={wordIndex} className="arranged-word" onClick={() => removeWord(sentenceIndex, wordIndex)}>
                {word}
              </span>
            ))}
          </div>
        </div>
      ))}

    </div>
  );
};

export default SentenceFormationExercise;
