import { addIntoCart, IApplicationState, IItems, removeIntoCart } from "@/store-redux";
import { Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

interface ListIdeaAIProps {
  ideas: any[];
}

const ListIdeaAI = ({ ideas }: ListIdeaAIProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const listMyIdeaPath = useSelector((state: IApplicationState) => state.myIdeaPath);

  const handleAddOrRemoveIntoCart = (idea: IItems) => {
    if (listMyIdeaPath.data.find((item) => item._id === idea._id)) {
      dispatch(removeIntoCart(idea));
    } else {
      const infoIdea = { ...idea, locationIdea: "" };
      dispatch(addIntoCart(infoIdea));
    }
  };

  return (
    <Box
      sx={{
        maxHeight: "300px",
        overflowY: "auto",
      }}
    >
      {ideas.map((idea) => {
        const isChecked = listMyIdeaPath.data.some((item) => idea._id === item._id);
        return (
          <Box
            onClick={() => handleAddOrRemoveIntoCart(idea)}
            key={idea._id}
            sx={{
              display: "flex",
              padding: "10px",
              cursor: "pointer",
              borderRadius: "8px",
              marginBottom: "5px",
              ...(isChecked ? { backgroundColor: "#F3F3FF" } : {}),
              position: "relative",
              // ...(idea.score !== undefined ? { borderColor: `${getColorByScore(idea.score)}!important` } : {}),
            }}
          >
            <Box
              sx={{
                width: "40px",
              }}
            >
              {isChecked ? (
                <CheckBoxIcon
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                />
              ) : (
                <CheckBoxOutlineBlankIcon
                  sx={{
                    color: "#D4D4D4",
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  textAlign: "left",
                  fontSize: "15px!important",
                  fontWeight: "bold",
                  "& *": {
                    fontSize: "15px!important",
                  },
                }}
                className="title"
                dangerouslySetInnerHTML={{ __html: idea.name?.replace(/\d+\.\s*&nbsp;/, "") || "" }}
              />
              <Box
                sx={{
                  textAlign: "left",
                  fontSize: "14px",
                }}
                className="description"
                dangerouslySetInnerHTML={{ __html: idea.description || "" }}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
export default ListIdeaAI;
