import React, { useState } from "react";
import { IUserAnswers, MultipleChoiceExerciseData } from "../types/exercise.types";

interface Props {
  data: MultipleChoiceExerciseData;
  isSubmitted?: boolean;
  setUserAnswers: React.Dispatch<React.SetStateAction<IUserAnswers>>;
}

const MultipleChoiceExercise: React.FC<Props> = ({ data, isSubmitted, setUserAnswers }) => {
  const [selectedAnswers, setSelectedAnswers] = useState<Record<number, string>>({});

  const handleSelect = (questionIndex: number, answer: string): void => {
    const newSelectedAnswers = {
      ...selectedAnswers,
      [questionIndex]: answer,
    };
    setSelectedAnswers(newSelectedAnswers);
    setUserAnswers((prev) => ({
      ...prev,
      multipleChoice: {
        ...prev.multipleChoice,
        ...newSelectedAnswers
      },
    }));
  };

  return (
    <div className="exercise-section">
      <h2>Multiple Choice</h2>

      <div className="questions">
        {data.questions.map((question, questionIndex) => (
          <div key={questionIndex} className="question">
            <p>{question.question}</p>
            <div className="options">
              {question.options.map((option, optionIndex) => (
                <label key={optionIndex} className="option">
                  <input
                    type="radio"
                    name={`question-${questionIndex}`}
                    value={option}
                    checked={selectedAnswers[questionIndex] === option}
                    onChange={() => handleSelect(questionIndex, option)}
                    style={{
                      backgroundColor:
                        isSubmitted && selectedAnswers[questionIndex] === option
                          ? option === question.correct_answer
                            ? "#e8f5e9"
                            : "#ffebee"
                          : "white",
                    }}
                    disabled={isSubmitted}
                  />
                  {option}
                </label>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultipleChoiceExercise;
