import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import StarIcon from "@mui/icons-material/Star";
import CircleIcon from "@mui/icons-material/Circle";
import { Box, IconButton } from "@mui/material";
import WordPhonetic from "./WordPhonetic";
import { onPlayReadStr } from "@/helpers";
const WordDetail = (props) => {
  const { word, isHidePhonetic } = props;

  const styleContentItemByType = {
    pos: {
      fontWeight: "bold",
      marginTop: "4px",
      color: "#7f8c8d",
    },
    define: { paddingLeft: "8px", fontSize: "15px" },
    example_en: {
      fontSize: "14px",
      paddingLeft: "16px",
      color: "#264653",
      cursor: "pointer",
      "&:hover": {
        color: "#7f8c8d",
      },
    },
    example_vi: {
      fontSize: "14px",
      paddingLeft: "45px",
      color: "#7f8c8d",
    },
  };
  const iconContentItemByType = {
    pos: <StarIcon fontSize="small" />,
    define: (
      <CircleIcon
        fontSize="small"
        sx={{
          width: "8px",
          height: "8px",
        }}
      />
    ),
    example_en: (
      <IconButton size="small">
        <VolumeUpIcon
          sx={{
            width: "15px",
            height: "15px",
          }}
        />
      </IconButton>
    ),
  };
  return (
    <>
      {!isHidePhonetic && <WordPhonetic word={word} isHiddenIPA={word?.word?.toString().split(" ")?.length > 1} />}
      <Box>
        {Array.isArray(word?.content) ? (
          <>
            {word?.content.map((contentI, indexI) => {
              return (
                <Box
                  key={indexI}
                  sx={() => ({
                    display: "flex",
                    alignItems: "center",
                    ...(styleContentItemByType[contentI.type] ?? {}),
                    "& svg": {
                      marginRight: "4px",
                    },
                  })}
                  onClick={(e) => {
                    if (contentI.type === "example_en") {
                      onPlayReadStr(contentI.text);
                      e.stopPropagation();
                    }
                  }}
                >
                  {iconContentItemByType[contentI.type] ?? <></>}
                  <span>{contentI.text}</span>
                </Box>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default WordDetail;
