import React, { useState } from "react";
import { IUserAnswers, MatchingExerciseData } from "../types/exercise.types";
import { Box } from "@mui/material";

interface Props {
  data: MatchingExerciseData;
  isSubmitted?: boolean;
  setUserAnswers: React.Dispatch<
    React.SetStateAction<IUserAnswers>
  >;
}

const MatchingExercise: React.FC<Props> = ({ data, isSubmitted, setUserAnswers }) => {
  const [selectedPairs, setSelectedPairs] = useState<Record<string, string>>({});

  const handleSelect = (vietnamese: string, english: string): void => {
    const newSelectedPairs = {
      ...selectedPairs,
      [vietnamese]: english,
    };
    setSelectedPairs(newSelectedPairs);
    setUserAnswers((prev) => ({
      ...prev,
      matching: newSelectedPairs as Record<string, string>, // Type assertion to fix type error
    }));
  };

  const isCorrectAnswer = (vietnamese: string, english: string) => {
    const pair = data.pairs.find((p) => p.vietnamese === vietnamese);
    return pair?.english === english;
  };

  return (
    <Box className="exercise-section" sx={{}}>
      <h2>Matching Exercise</h2>
      <p>{data.instructions}</p>

      <Box
        className="matching-container"
        sx={{
          display: "flex",
          gap: "30px",
        }}
      >
        <div className="vietnamese-column">
          {data.pairs.map((pair) => (
            <div key={pair.vietnamese} className="matching-item">
              {pair.vietnamese}
            </div>
          ))}
        </div>

        <div className="english-column">
          {data.pairs.map((pair) => (
            <div key={pair.english} className="matching-item">
              <select
                onChange={(e) => handleSelect(pair.vietnamese, e.target.value)}
                value={selectedPairs[pair.vietnamese] || ""}
                style={{
                  backgroundColor: isSubmitted
                    ? isCorrectAnswer(pair.vietnamese, selectedPairs[pair.vietnamese])
                      ? "#e8f5e9" // green background for correct
                      : "#ffebee" // red background for incorrect
                    : "white",
                }}
                disabled={isSubmitted}
              >
                <option value="">Select translation</option>
                {data.pairs.map((p) => (
                  <option key={p.english} value={p.english}>
                    {p.english}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </div>
      </Box>
    </Box>
  );
};

export default MatchingExercise;
