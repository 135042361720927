import { SVGProps } from "react";

function ConversationIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="#000000"
      height="800px"
      width="800px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 490 490"
      {...props}
    >
      <g>
        <g>
          <g>
            <circle cx="367.5" cy="85.6" r="51.3" />
            <path d="M367.5,160.6c-67.7,0-122.5,54.8-122.5,122.5h245C490,215.4,435.2,160.6,367.5,160.6z" />
            <circle cx="122.5" cy="292.1" r="51.3" />
            <path d="M122.5,367.5C54.8,367.5,0,422.3,0,490h245C245,422.3,190.2,367.5,122.5,367.5z" />
            <path
              d="M217,180.4V131h31.1V0H22.6v131.1h142.7L217,180.4z M196,55.6h5.1c5.4,0,10.1,4.3,10.1,10.1s-4.3,10.1-10.1,10.1H196
				c-5.4,0-10.1-4.3-10.1-10.1S190.6,55.6,196,55.6z M74.7,75.4h-5.1c-5.4,0-10.1-4.3-10.1-10.1c0-5.8,4.3-10.1,10.1-10.1h5.1
				c5.4,0,10.1,4.3,10.1,10.1C84.8,71.2,80.1,75.4,74.7,75.4z M137.7,75.4h-5.1c-5.4,0-10.1-4.3-10.1-10.1c0-5.8,4.3-10.1,10.1-10.1
				h5.1c5.4,0,10.1,4.3,10.1,10.1C147.8,71.2,143.1,75.4,137.7,75.4z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export { ConversationIcon };
