import { IApplicationState } from "@/store-redux";
import BoltOutlined from "@mui/icons-material/BoltRounded";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

const RemainingEnergy = () => {
  const theme = useTheme();
  const remainingEnergy = useSelector((state: IApplicationState) => state.remainingEnergy);
  if (!remainingEnergy?.data) return <></>;
  return (
    <Tooltip
      title={
        <Typography
          sx={{
            fontSize: "12px",
            textAlign: "center",
          }}
        >
          You have 1295 energy and it will be reset every day
        </Typography>
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderRadius: "8px",
          background: "white",
          paddingRight: "8px",
          width: 'max-content',
        }}
      >

        <BoltOutlined
          sx={{ height: "16px" }}
          color="disabled"
        />
        <Typography
          sx={{
            fontSize: "12px",
            color: theme.palette.text.primary,
            fontWeight: "bold",
            cursor: "default",
          }}
        >
          {remainingEnergy?.data?.remain ?? " "}
        </Typography>
      </Box>
    </Tooltip>
  );
};
export default RemainingEnergy;
