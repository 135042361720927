import { TabContext, TabList } from "@mui/lab";
import { Box, Breadcrumbs, SxProps, Tab, Theme, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

interface ILayoutTabProps {
  children: JSX.Element;
  tabValue?: any;
  setTabValue?: (id: number | string) => void;
  listTab: { id: number | string; label: string }[];
  title: string;
  subTitle: string;
  breadcrumbs?: { text: string; link?: string }[];
  sxContainer?: SxProps<Theme>;
}

function LayoutTab({
  children,
  tabValue,
  setTabValue,
  listTab,
  title,
  subTitle,
  breadcrumbs,
  sxContainer = {},
}: ILayoutTabProps) {
  const theme = useTheme()
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTabValue && setTabValue(newValue);
  };
  const hasBreadcrumbs = Array.isArray(breadcrumbs) && breadcrumbs.length > 0;
  return (
    <Box sx={{ width: "100%", paddingInline: "24px", paddingBottom: 0, paddingTop: "12px", ...sxContainer }}>
      {hasBreadcrumbs && (
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            "& a": {
              color: theme.palette.primary.main,
            },
          }}
        >
          {breadcrumbs.map((breadcrumb, idx) => {
            if (breadcrumb.link) {
              return (
                <Link to={breadcrumb.link} key={idx}>
                  {breadcrumb.text}
                </Link>
              );
            } else {
              return (
                <Typography sx={{ color: "text.primary" }} key={idx}>
                  {breadcrumb.text}
                </Typography>
              );
            }
          })}
        </Breadcrumbs>
      )}
      <Typography
        component={"h2"}
        className="layout_title"
        sx={{
          color: "#000",
          fontSize: "24px",
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: "#6A6969",
          fontSize: "14px",
        }}
      >
        {subTitle}
      </Typography>
      <TabContext value={tabValue}>
        {listTab.length > 0 && (
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                sx: {
                  height: "2px",
                },
              }}
              sx={(theme) => ({
                "& button": {
                  textTransform: "none",
                  color: theme.palette.secondary.main,
                },
                "& .Mui-selected": {
                  fontWeight: "bold",
                  color: theme.palette.secondary.main,
                },
              })}
            >
              {listTab.map((tab) => (
                <Tab label={tab.label} value={tab.id} key={tab.id} />
              ))}
            </TabList>
          </Box>
        )}
        <Box sx={{}}>{children}</Box>
      </TabContext>
    </Box>
  );
}

export default LayoutTab;
