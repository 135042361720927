import { Box, Button, CircularProgress, MenuItem, Modal, TextField, Typography } from "@mui/material";
import { ERole, IUser } from "../../../../../store-redux";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { AppDatePicker } from "@/components/FormControls";

interface IModalAddEditUserProps {
  open: boolean;
  onClose: () => void;
  onSave: (user: IFormData) => void;
  loading: boolean;
  titleModal: string;
  data?: IUser;
}

export interface IFormData extends Omit<IUser, "_id" | "expiredTime"> {
  password: string;
  expiredTime: string | Date;
}

export default function ModalAddEditUser({ open, onClose, onSave, loading, titleModal, data }: IModalAddEditUserProps) {
  const { control, handleSubmit, reset } = useForm<IFormData>({
    defaultValues: {
      username: "",
      email: "",
      password: "",
      role: ERole.USER,
    },
  });
  useEffect(() => {
    if (open) {
      reset({
        username: data?.username || "",
        email: data?.email || "",
        password: "",
        role: data?.role || ERole.USER,
        expiredTime: data?.expiredTime ? new Date(data?.expiredTime) : "",
        active: data?.active ?? true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, reset, data]);
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "8px",
          minWidth: {
            xs: "100vw",
            sm: "500px",
          },
          maxWidth: {
            xs: "100vw",
            sm: "600px",
          },
          width: "100%",
          "& form": {
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          },
        }}
      >
        <form onSubmit={handleSubmit(onSave)}>
          <Typography variant="h5">{titleModal}</Typography>
          <Controller
            name="username"
            control={control}
            render={({ field }: { field: any }) => <TextField {...field} label="Username" />}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }: { field: any }) => <TextField {...field} label="Email" type="email" required />}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }: { field: any }) => <TextField {...field} label="Password" required={!data} />}
          />
          <Controller
            name="role"
            control={control}
            render={({ field }: { field: any }) => (
              <TextField {...field} select label="Role" defaultValue={ERole.USER}>
                <MenuItem value={ERole.USER}>User</MenuItem>
                <MenuItem value={ERole.TEACHER}>Teacher</MenuItem>
                <MenuItem value={ERole.ADMIN}>Admin</MenuItem>
                <MenuItem value={ERole.SUPER_ADMIN}>Super Admin</MenuItem>
              </TextField>
            )}
          />
          <AppDatePicker control={control} name="expiredTime" label="Expired Date" />
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="secondary" disabled={loading}>
              {loading ? <CircularProgress size={30} /> : "Save"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}
