import { LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IApplicationState } from "../../../../store-redux";
import ListSuggest from "../IdeaPage/components/ListSuggest";
import { styles } from "../../styles";

interface ILayoutLearnProps {
  children: JSX.Element;
  isLoading?: boolean;
}

function LayoutLearn({ children, isLoading }: ILayoutLearnProps) {
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const { pathname } = useLocation();
  const listMyIdeaPath = useSelector((state: IApplicationState) => state.myIdeaPath);
  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);
  const isShowMyPath =
    (pathname.indexOf("/learn/idea") !== -1 && listMyIdeaPath.data?.length > 0) ||
    (pathname.indexOf("/answer") !== -1 && listMyIdeaPath.data?.length > 0) ||
    listMyIdeaPath.data?.find((item) => item?.genByAI);

  const [heightWithUnit, setHeightWithUnit] = useState("0px");

  useEffect(() => {
    const myBox = document.querySelector(".question");

    if (myBox !== null) {
      const computedStyles = window.getComputedStyle(myBox);

      const newHeightWithUnit = computedStyles.height;
      setHeightWithUnit(newHeightWithUnit);
    }
  }, [detailQuestion]);

  return (
    <Box
      sx={() => ({
        position: "relative",
        backgroundColor: "#fff",
        textAlign: "center",
        overflow: "hidden",
        // height: "100%",
        "& .box-header": {
          marginTop: "20px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        },
        "& h1": {
          color: "#000",
          width: "auto",
          fontFamily: "Poppins",
          fontSize: "36px",
          fontWeight: 700,
          lineHeight: "54px",
          letterSpacing: "0em",
          textAlign: "left",
        },
        "& .box-container": {
          maxHeight: "calc(100vh - 132px)",
        },
        "& .box-container--has-question": {
          maxHeight: `calc(100vh - ${heightWithUnit} - 135px)`,
        },
      })}
    >
      <Box className="box-header">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // width: "calc(100vw - 32px)",
            "& button": {
              border: "2px solid #000",
              borderRadius: "8px",
              minWidth: "50px",
              padding: "0px 8px 0px 8px",
              textTransform: "none",
              "& h4": {
                fontFamily: "Poppins",
                color: "black",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "30px",
                letterSpacing: "0em",
              },
            },
            "& Button:hover": {
              backgroundColor: "#ECEAEA",
            },
          }}
        >
          {/* {pathname.indexOf("/learn/idea") !== -1 ? (
            <Button onClick={() => setIsModalOpen(true)}>
              <Typography variant="h4"> Grammar & structure</Typography>
            </Button>
          ) : (
            ""
          )} */}

          {/* <GrammarStructure
            isModalOpen={isModalOpen}
            handleCloseGS={() => {
              setIsModalOpen(false);
            }}
          /> */}
        </Box>
        {isLoading ? <LinearProgress color="secondary" style={{ marginRight: 10 }} /> : null}
        <Box sx={styles.boxContainer}>{children}</Box>
      </Box>
      {isShowMyPath ? (
        <Box
          sx={() => ({
            width: "100%",
            position: "absolute",
            bottom: "0px",
            top: "auto",
            left: "0",
            right: "0",

            "& .MuiAccordionSummary-content": {
              fontWeight: "bold",
            },
            "& .MuiAccordionSummary-expandIconWrapper": {
              "& svg": {
                fill: "black",
              },
            },
            "& .MuiAccordionDetails-root": {
              "& div": {
                fontWeight: "bold",
              },
            },
          })}
        >
          <ListSuggest />
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}

export default LayoutLearn;
