import * as React from "react";
export const Part3Icon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={320} height={320} viewBox="0 0 320 320" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M25.952 144.352C25.952 152.128 28.384 184.384 29.664 201.024C30.112 206.72 34.848 211.104 40.576 211.104H49.696"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.832 144.352C60.832 152.128 63.264 184.384 64.576 201.024C65.024 206.72 69.76 211.104 75.456 211.104H84.608"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.56 206.784C21.088 206.784 20.512 208.512 21.696 209.248C25.184 211.456 29.312 212.64 33.632 212.64H103.072C107.392 212.64 111.52 211.456 115.008 209.248C116.192 208.512 115.616 206.784 114.144 206.784H22.56Z"
      fill="#FF95FB"
    />
    <path
      d="M43.392 165.6C51.84 165.6 58.24 164.8 62.816 163.904C68.288 162.816 72.608 158.624 73.92 153.216C74.976 148.832 75.936 143.68 75.648 140.352C75.104 134.112 70.208 130.72 64.064 131.904C57.984 133.056 50.176 134.24 43.392 134.24C36.608 134.24 28.8 133.056 22.72 131.904C16.576 130.72 11.68 134.112 11.136 140.352C10.848 143.68 11.808 148.832 12.864 153.216C14.176 158.624 18.496 162.816 24 163.904C28.544 164.8 34.944 165.6 43.392 165.6Z"
      fill="#FF95FB"
    />
    <path
      d="M26.624 142.848C26.624 143.68 25.952 144.352 25.12 144.352C24.288 144.352 23.584 143.68 23.584 142.848C23.584 142.016 24.288 141.344 25.12 141.344C25.952 141.344 26.624 142.016 26.624 142.848Z"
      fill="white"
    />
    <path
      d="M27.456 153.728C27.456 154.56 26.784 155.232 25.952 155.232C25.12 155.232 24.448 154.56 24.448 153.728C24.448 152.864 25.12 152.192 25.952 152.192C26.784 152.192 27.456 152.864 27.456 153.728Z"
      fill="white"
    />
    <path
      d="M60.16 142.848C60.16 143.68 60.832 144.352 61.664 144.352C62.528 144.352 63.2 143.68 63.2 142.848C63.2 142.016 62.528 141.344 61.664 141.344C60.832 141.344 60.16 142.016 60.16 142.848Z"
      fill="white"
    />
    <path
      d="M59.328 153.728C59.328 154.56 60 155.232 60.832 155.232C61.664 155.232 62.368 154.56 62.368 153.728C62.368 152.864 61.664 152.192 60.832 152.192C60 152.192 59.328 152.864 59.328 153.728Z"
      fill="white"
    />
    <path
      d="M73.376 278.912L67.744 223.776C67.104 217.44 62.272 212.64 56.512 212.64H39.008C33.248 212.64 28.416 217.44 27.776 223.776L22.176 278.912"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M23.6481 264.512H71.9041" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M114.304 278.912L108.672 223.776C108.032 217.44 103.2 212.64 97.44 212.64H79.936C74.208 212.64 69.344 217.44 68.704 223.776L63.104 278.912"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M64.576 264.512H112.832" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M121.792 251.008C121.792 251.008 121.632 233.92 121.504 228.352C121.376 222.784 119.584 212.864 121.504 205.984C121.504 205.984 107.456 206.112 101.024 205.984V175.616C101.024 175.616 116.224 175.04 126.08 175.712C136.544 176.448 142.24 179.616 144.384 182.272C147.136 185.664 147.744 189.472 146.656 202.752C145.344 219.104 141.568 254.176 141.568 254.176C141.568 254.176 138.176 259.04 128.544 259.04C118.592 259.04 121.792 251.008 121.792 251.008Z"
      fill="#FFCCCC"
    />
    <path
      d="M164.992 264.544C161.28 263.552 159.168 262.752 155.936 260.864C152.224 258.72 145.632 253.408 144.128 252.16C142.144 250.528 137.28 250.784 134.912 254.208C134.08 253.824 133.216 253.536 132.352 253.696C131.52 253.856 130.848 254.4 130.176 254.912C128.992 255.904 127.04 257.568 124.224 257.216C121.504 256.864 121.824 253.76 121.824 253.76L121.792 251.008C120.352 250.88 119.616 251.52 119.36 253.376C119.008 255.936 118.816 256.864 118.176 261.312C117.792 263.872 116.512 267.712 117.728 273.44C118.208 276.608 121.632 275.264 126.528 275.264C132.8 275.264 154.016 275.264 154.016 275.264C158.528 275.264 165.504 275.264 168.224 275.264C170.944 275.264 173.088 273.056 173.024 272.096C172.8 267.968 171.104 266.144 164.992 264.544Z"
      fill="white"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M134.208 264C129.44 262.08 127.744 261.504 118.176 261.312C117.664 263.648 116.704 268.512 117.728 273.44C118.208 276.608 121.632 275.264 126.528 275.264C132.8 275.264 154.016 275.264 154.016 275.264C154.379 275.264 154.763 275.264 155.168 275.264L155.488 273.856C148.096 273.856 140.8 266.656 134.208 264Z"
      fill="#F55571"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M150.848 266.432C152.704 264.064 156.64 261.824 160.096 262.976"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M154.24 259.808C152.576 260.704 151.648 261.856 150.528 263.712"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M155.936 260.864C153.824 260.8 150.816 261.408 145.76 262.24"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M150.048 257.024C148.416 257.92 147.616 258.816 146.496 260.672"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M151.776 258.08C149.632 257.984 146.624 258.592 141.568 259.456"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M145.952 253.76C144.32 254.656 143.296 256.256 142.176 258.112"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M147.584 255.296C145.472 255.2 142.464 255.808 137.376 256.672"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M171.168 278.4C168.736 279.072 155.36 278.976 155.36 278.976C155.36 278.976 133.152 278.976 128.064 278.976C122.976 278.976 119.232 278.816 117.92 277.024C117.152 275.968 117.248 275.232 117.472 272.512C117.472 272.512 119.584 273.92 128.256 273.92C136.96 273.92 158.144 273.984 162.752 273.92C165.984 273.888 170.944 274.304 173.024 272.096C173.792 273.12 174.848 277.408 171.168 278.4Z"
      fill="white"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M108.704 171.584C108.704 171.584 117.728 172.064 127.072 173.184C133.344 173.984 141.312 175.104 146.432 181.248C151.36 187.2 150.048 197.408 149.696 202.24C148.768 215.104 146.432 239.872 146.432 239.872C143.04 245.248 121.024 245.248 119.552 239.872C118.368 217.216 118.688 209.92 118.624 206.784C118.624 206.784 87.648 206.816 79.712 206.816V169.184H107.392L108.704 171.584Z"
      fill="#00826F"
    />
    <path
      d="M126.368 194.4C124.48 195.456 119.68 199.296 118.624 206.784"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M94.6561 173.184C96.4801 184.64 95.3921 200.928 89.6321 206.784"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M83.4241 196.256H119.232C119.232 196.256 118.816 198.688 121.792 198.72C121.792 201.024 123.36 202.304 125.12 202.304C125.12 202.304 122.336 211.936 126.368 244.512"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M121.92 192.736C119.744 194.656 118.208 197.344 117.728 200.192"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M145.728 245.6C145.216 249.152 119.84 248.608 119.84 246.176L119.552 239.872C119.552 242.208 146.08 242.304 146.432 239.872L145.728 245.6Z"
      fill="#303030"
    />
    <path
      d="M68.16 253.472C67.808 245.696 65.024 206.56 65.024 206.56H57.824C57.824 206.56 57.824 200.576 57.824 180.16C57.824 180.16 82.08 178.56 86.816 182.144C91.008 185.28 91.52 192.384 90.304 205.984C88.416 227.264 84.128 268.704 84.128 268.704L69.056 269.568L68.16 253.472Z"
      fill="#FFCCCC"
    />
    <path
      d="M90.048 182.144C87.136 178.56 82.688 177.984 82.688 177.984V169.184H52.448C48.128 182.784 47.04 193.568 48.608 198.88C50.464 205.056 55.008 207.04 60.576 207.04H62.528L64.192 241.696C64.192 241.696 66.304 243.872 76.736 243.904C89.568 243.968 90.688 239.52 90.688 239.52C90.688 239.52 91.52 221.088 92.544 205.696C93.568 190.304 92.736 185.472 90.048 182.144Z"
      fill="#00826F"
    />
    <path
      d="M64.5119 245.376C64.5119 245.376 65.4079 247.168 76.3199 247.168C89.4079 247.168 90.2079 245.248 90.2079 245.248L90.6879 239.232C90.6879 239.232 88.0319 241.152 75.5839 241.152C64.4799 241.152 64.0959 238.976 64.0959 238.976L64.5119 245.376Z"
      fill="#303030"
    />
    <path
      d="M64.864 180.48C69.472 178.4 74.432 176.704 79.456 176.992C84.512 177.28 88.544 178.624 90.88 183.104C94.176 189.472 93.024 196.672 92.832 200.16C92.6827 203.147 92.5227 206.144 92.352 209.152"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.872 172.544C53.952 185.184 52.32 191.328 55.136 196.256C57.056 199.584 61.824 199.968 61.824 199.968C61.824 199.968 62.656 202.752 65.152 202.016C64.224 208.608 67.2 243.552 67.2 243.552"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.576 198.592C64.864 200.576 63.36 204.48 62.656 206.88"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.7439 197.952C62.1759 199.072 61.0879 200.8 60.7679 202.688"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M102.4 272.128C100.096 274.304 94.9759 273.888 91.3919 273.92C90.5279 273.92 80.8319 273.92 79.0399 273.92C69.3759 273.92 66.7839 272.768 66.7839 272.768C66.7839 274.88 66.6879 275.968 67.5519 277.024C68.9919 278.848 73.1519 278.976 78.7839 278.976C80.3839 278.976 89.7279 278.976 90.2719 278.976C90.2719 278.976 96.5759 278.912 99.2959 278.4C102.88 277.76 102.4 275.008 102.4 272.128Z"
      fill="white"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.7681 257.6L68.3201 253.472C68.3201 253.472 66.4321 253.28 66.7841 256.096C67.5201 261.824 67.4561 262.688 66.9761 266.816C66.5281 270.56 66.7841 272.768 66.7841 272.768C66.7841 272.768 68.8321 274.048 76.4481 274.4C86.4641 274.848 90.4001 274.784 95.1361 274.56C100.32 274.304 101.248 273.376 102.4 272.128C102.4 267.712 100.736 266.304 97.7921 264.928C94.2081 263.296 92.4161 261.504 90.7201 259.04C89.9201 257.92 89.4721 256.928 88.7041 254.624C88.0001 252.512 85.8881 250.816 80.0001 251.328C70.9121 252.16 68.7681 257.6 68.7681 257.6Z"
      fill="white"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M97.536 264.768C88.448 261.184 81.472 265.024 78.624 266.464"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.312 263.936C81.824 261.312 87.168 259.968 91.264 259.808"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M93.504 262.272C90.112 260.352 82.528 260.864 75.616 261.504"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.304 259.616C79.072 256.96 85.504 256.32 89.248 256.128"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M90.112 258.496C87.008 256.608 79.808 256.608 73.504 257.248"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.784 272.768C66.784 272.768 68.864 274.048 76.448 274.4C78.976 274.496 81.12 274.592 82.976 274.656C77.184 274.176 74.784 271.776 73.504 270.432C71.264 268 70.912 263.744 67.328 262.08C67.328 262.101 67.328 262.112 67.328 262.112C67.36 263.52 67.2 264.768 66.976 266.816C66.8906 267.52 66.8266 268.16 66.784 268.736C66.7626 269.141 66.7413 269.515 66.72 269.856C66.6986 270.539 66.6986 271.104 66.72 271.552C66.72 272.352 66.784 272.768 66.784 272.768Z"
      fill="#F55571"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M72.384 84.864C72.096 92.384 78.24 100.8 86.112 101.088C93.952 101.376 100.544 93.44 100.832 85.92C101.088 78.432 94.944 72.096 87.104 71.808C79.264 71.52 72.672 77.376 72.384 84.864Z"
      fill="#303030"
    />
    <path
      d="M76.0639 90.336C74.8799 90.4 73.8879 90.144 72.9279 89.344C71.8719 88.48 71.6479 86.496 71.9039 85.472C71.4239 85.568 70.9439 85.632 70.4639 85.568C69.9839 85.536 69.4719 85.376 69.1519 84.992C68.9279 84.8 68.8319 84.384 69.0879 84.224C69.1839 84.16 69.3439 84.16 69.4719 84.16C70.1439 84.096 70.6559 83.488 70.8479 82.848C71.0399 82.208 71.0079 81.504 71.0399 80.832C71.1999 78.144 73.0879 74.624 75.9359 74.016C76.9599 73.792 78.0159 73.92 79.0399 74.112C79.5199 72.416 80.6719 70.912 82.1119 69.888C83.5519 68.832 85.2799 68.224 87.0399 67.968C88.2239 67.776 89.4719 67.744 90.6239 68.032C92.8319 68.544 94.6239 70.048 96.5759 71.104C98.5279 72.192 101.088 72.8 102.944 71.584C103.264 71.36 103.68 71.072 104.032 71.296C104.16 71.392 104.224 71.552 104.288 71.68C104.928 73.408 104.032 75.2 102.624 76.256C103.84 76.288 105.024 76.736 105.76 77.92C106.464 79.04 106.656 80.704 106.432 82.016C105.792 81.344 104.928 80.896 104.032 80.704C104.896 82.016 105.024 83.744 104.416 85.152C103.904 86.24 102.976 87.136 101.856 87.584C101.248 87.84 100.704 87.776 100.096 87.872C99.2853 88.0214 98.4853 88.16 97.6959 88.288C94.4746 88.8 91.2426 89.184 87.9999 89.44C86.0159 89.6 84.0639 89.664 82.0799 89.76C80.0639 89.888 78.0799 90.208 76.0639 90.336Z"
      fill="#303030"
    />
    <path
      d="M100.576 91.3603C100.736 89.2483 100.928 88.0003 100.864 85.3123C100.736 81.3443 98.6239 78.1763 98.6239 78.1763C96.1919 74.2083 89.1839 71.5203 83.5839 77.8883C80.3839 77.9203 78.7519 79.6163 78.1439 82.0803C79.1679 84.1603 80.2559 86.4003 80.0959 89.3763C79.9999 91.4243 79.5519 91.5203 78.7839 90.5283C77.7279 89.2803 76.1599 88.1923 74.4639 88.1283C72.2879 88.0963 70.8479 89.3443 70.4639 90.9123C70.1119 92.4803 71.3279 94.1763 72.8319 94.8483C74.3359 95.5203 76.3519 95.5523 77.7599 94.6563C77.7599 94.6563 77.7599 96.5763 77.7919 98.2083C77.7919 99.8403 78.3359 101.344 79.4559 102.528L78.7519 110.944L73.9519 112.544C75.1679 113.92 82.4639 122.592 90.0799 123.104C97.6639 123.584 97.6319 112.832 97.6319 112.832L92.9279 111.648L92.9919 106.944C93.9199 106.944 97.5039 106.88 99.1679 105.216C100.864 103.584 100.672 101.344 100.544 97.8243C100.48 95.9043 100.448 93.0883 100.576 91.3603Z"
      fill="#FFCCCC"
    />
    <path
      d="M93.9839 88.8962C94.2079 88.0002 95.0719 87.4882 95.9999 87.3602C96.9599 87.2322 98.3679 87.3602 98.6879 88.6722C98.4319 90.0482 95.4239 90.6562 93.9839 88.8962Z"
      fill="white"
    />
    <path
      d="M98.0479 89.2479C97.7919 89.8559 97.0559 90.1439 96.4479 89.8879C95.8079 89.6319 95.4879 88.9279 95.7439 88.3199C95.9999 87.7119 96.7039 87.4239 97.3439 87.6799C97.9839 87.9679 98.3039 88.6399 98.0479 89.2479Z"
      fill="#303030"
    />
    <path d="M95.6479 88.2881L96.8959 88.8001L95.5199 88.9601L95.6479 88.2881Z" fill="white" />
    <path
      d="M98.8479 88.416C98.1759 87.488 97.0559 87.328 96.0959 87.456C95.1679 87.584 94.2079 88.256 93.9839 88.896"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M95.1998 82.208C97.1198 82.112 99.0398 82.976 99.9998 84.128"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M84.3839 89.0561C84.6079 88.1601 85.4399 87.6801 86.3999 87.5201C87.3599 87.3921 88.7359 87.5201 89.0879 88.8641C88.7999 90.2401 85.7919 90.8161 84.3839 89.0561Z"
      fill="white"
    />
    <path
      d="M88.4159 89.4401C88.1599 90.0161 87.4559 90.3041 86.8159 90.0481C86.1759 89.7921 85.8879 89.0881 86.1119 88.4801C86.3679 87.8721 87.1039 87.6161 87.7119 87.8721C88.3519 88.1281 88.6719 88.8321 88.4159 89.4401Z"
      fill="#303030"
    />
    <path d="M86.0479 88.4482L87.2639 88.9602L85.9199 89.1202L86.0479 88.4482Z" fill="white" />
    <path
      d="M89.2159 88.6079C88.5439 87.6479 87.4559 87.4879 86.4959 87.6159C85.5359 87.7759 84.5759 88.4159 84.3839 89.0559"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.528 85.408C83.264 83.776 85.344 82.368 87.264 82.208"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.3519 85.9199C92.7679 88.6719 93.8239 90.3999 94.4639 91.8079C94.9759 92.8639 95.0079 94.6879 92.3519 94.7839"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M72.5439 91.6161C72.9279 91.3281 73.8879 91.1041 74.8799 91.3281C75.8719 91.5841 76.7039 92.3521 76.9919 93.2801"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.24 92.6401C74.528 92.4481 75.552 91.9041 76.544 92.4481"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.3999 88.2564C82.3999 90.7204 84.4159 92.7044 86.8799 92.7044C89.3439 92.6724 91.3279 90.6884 91.3279 88.2244C91.2959 85.7604 89.3119 85.5684 86.8479 85.5684C84.3839 85.5684 82.3999 85.7924 82.3999 88.2564Z"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M94.1759 88.2241C94.1759 90.6881 96.1919 92.6721 98.6559 92.6721C101.12 92.6721 103.104 90.6561 103.104 88.1921C103.104 85.7281 101.088 85.5361 98.6239 85.5361C96.1599 85.5361 94.1759 85.7601 94.1759 88.2241Z"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M94.2079 89.5041C93.3759 88.6401 91.8719 88.8001 91.2959 89.5041"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M82.2079 88.96L79.0079 89.696" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M88.1919 97.1201C89.9519 98.4321 92.3519 98.8161 94.4319 98.0481"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M83.584 77.8882C87.328 75.1682 91.872 75.7762 94.208 79.8082C96.256 83.2642 99.392 86.4322 102.752 84.8642C100.704 82.2082 102.304 77.5042 97.472 73.6322C93.408 70.3682 83.616 70.2402 83.584 77.8882Z"
      fill="#303030"
    />
    <path
      d="M92.9919 106.944C89.4719 106.624 85.5679 105.6 83.3279 104.064C83.8719 105.6 86.3999 108.128 92.9599 109.408L92.9919 106.944Z"
      fill="#303030"
    />
    <path
      d="M159.552 107.488C157.952 108.16 156.32 109.856 154.304 111.616C153.984 111.456 153.632 111.264 153.184 111.072C152.928 110.944 152.064 110.688 151.776 110.752C151.36 110.848 151.072 111.232 151.04 111.648C150.976 112.352 151.072 113.056 151.648 113.696C151.563 113.76 151.488 113.824 151.424 113.888C150.752 113.6 150.208 113.504 149.792 113.824C149.504 114.08 149.408 114.56 149.44 115.04C147.52 115.968 145.92 116.192 146.528 114.912C146.912 114.048 148.032 113.248 148.576 112.352C149.6 110.656 149.536 109.472 148.704 109.344C146.976 109.056 144.864 111.712 141.568 114.592C139.264 116.576 138.112 121.248 137.312 123.328C135.264 128.672 131.84 136.576 128.64 142.848C128.64 142.848 119.68 129.76 114.912 123.84C110.144 117.888 107.712 117.728 107.712 117.728L106.528 144.64C106.528 144.64 112.352 150.24 119.328 157.408C126.304 164.608 130.72 166.784 134.976 163.648C139.232 160.512 139.392 156.544 141.024 149.888C142.976 141.856 144.768 133.344 146.272 131.456C148.64 128.448 151.744 127.072 153.504 125.536C154.4 124.768 154.528 124.096 154.848 122.88C156.992 121.12 157.344 120 156.928 118.048H156.96C156.939 117.856 156.896 117.675 156.832 117.504C158.72 115.904 158.528 114.368 157.664 113.728C158.24 112.928 161.056 109.312 161.376 108.608C161.792 107.584 160.48 107.104 159.552 107.488Z"
      fill="#FFCCCC"
    />
    <path
      d="M157.664 113.728C155.872 112.384 155.264 112.032 153.184 111.072C152.928 110.944 152.064 110.688 151.776 110.752C151.36 110.848 151.072 111.232 151.04 111.648C150.976 112.576 151.136 113.504 152.416 114.336"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M156.96 118.048C156.864 117.216 156.352 116.512 155.648 116.096C154.816 115.584 154.08 115.2 153.184 114.752C151.744 114.048 150.528 113.248 149.792 113.824C149.312 114.208 149.376 115.296 149.568 115.808C149.792 116.448 150.272 116.992 150.816 117.344"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M154.848 122.88C155.168 120.64 155.424 119.872 154.752 119.168C153.92 118.304 151.904 117.728 150.752 117.376C150.24 117.216 149.696 117.056 149.152 117.056C149.024 117.056 148.896 117.056 148.768 117.12C148.64 117.216 148.544 117.344 148.48 117.472C148.224 118.08 148.512 118.784 148.96 119.264C149.472 119.744 150.72 120.416 151.296 120.8C151.328 120.832 151.392 120.896 151.424 120.96C151.456 121.024 151.424 121.088 151.392 121.184C151.264 122.016 150.912 122.624 150.592 123.136"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M146.368 121.184C144.992 121.344 143.776 122.24 142.944 123.328C142.112 124.448 141.568 125.76 141.056 127.04"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M144.544 136.896C136.576 136.544 133.568 131.424 133.568 131.424C132.256 131.104 131.904 131.68 130.976 133.568C130.176 135.168 128.32 139.392 128.32 139.392C128.32 139.392 126.784 137.408 122.176 131.2C114.912 121.376 112.128 116.288 106.208 114.592L104.8 145.504C104.8 145.504 109.984 151.552 119.84 160.736C124.864 165.44 127.136 167.296 131.936 167.296C136.704 167.296 138.688 164.512 140.16 161.568C141.696 158.56 142.368 155.456 143.712 149.184C144.896 143.552 146.784 136.896 144.544 136.896Z"
      fill="#F55571"
    />
    <path
      d="M133.568 131.424C133.568 131.424 136.576 136.544 144.544 136.896L145.76 133.376C145.76 133.376 142.56 133.344 139.648 131.68C136.768 130.016 134.976 127.584 134.976 127.584L133.568 131.424Z"
      fill="#303030"
    />
    <path
      d="M128.576 138.656C126.656 142.24 125.28 145.888 125.408 150.624"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.992 111.296L106.208 114.592C106.208 114.592 110.624 118.912 111.648 125.568C112.064 128.256 111.808 134.592 111.488 138.816C110.912 147.072 111.072 150.976 110.688 158.848C110.368 165.856 113.504 170.624 107.104 173.184C96.16 177.632 62.304 178.304 53.6 170.912C49.728 167.648 51.072 164.544 52.672 156.416C54.496 147.104 56 137.344 56.8 131.68C58.4 120.352 60.16 115.712 65.6 113.92C71.744 111.904 78.752 110.752 78.752 110.752C82.4 114.24 92.768 114.016 92.992 111.296Z"
      fill="#F55571"
    />
    <path
      d="M111.648 125.568C112.064 128.256 111.808 134.592 111.488 138.816C110.912 147.072 111.072 150.976 110.688 158.848"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.976 111.744C76.544 114.592 82.4 117.024 88.512 116.672C95.52 116.256 96.416 113.408 96.352 112.128"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M98.2401 166.912C98.2401 166.912 102.976 166.912 105.472 166.912C107.968 166.912 108.32 166.848 111.072 167.2C114.464 167.584 116.128 165.888 116.128 165.888C116.128 165.888 116.16 167.52 119.936 166.56C121.024 166.272 122.176 166.208 122.4 164.864C124.288 164.32 124.832 162.688 124.704 161.728C124.704 161.728 126.784 162.944 128.16 163.936C129.184 164.672 130.72 165.792 132.128 164.64C133.024 163.904 129.792 160.48 129.792 160.48C129.792 160.48 131.296 161.76 132.672 162.08C133.856 162.336 135.136 161.888 134.944 161.056C134.816 160.448 132.256 157.92 131.168 157.056C126.976 153.728 125.344 152.448 122.624 152.8C119.136 153.248 112.96 154.528 110.912 154.624C108.864 154.72 77.9521 151.008 77.9521 151.008L73.6001 132.16C73.6001 132.16 68.9281 117.344 66.3681 114.88C57.3121 119.328 53.2801 120.8 55.1681 132.736C57.0881 144.672 59.9681 157.056 61.2801 160.192C62.5921 163.328 65.5361 165.728 72.1281 165.952C78.7201 166.208 98.2401 166.912 98.2401 166.912Z"
      fill="#FFCCCC"
    />
    <path
      d="M119.104 160.417C120.128 160.545 120.96 160.705 121.44 161.665C121.92 162.625 122.336 164.001 122.112 165.057C121.952 165.889 121.248 166.208 120.48 166.432C119.552 166.688 118.56 166.849 117.568 166.881C117.312 166.913 117.024 166.912 116.8 166.752C116.384 166.464 116.384 165.696 116.672 165.312C116.96 164.928 117.6 164.609 118.08 164.545"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M122.016 158.017C123.424 159.041 124.416 160.609 124.704 162.305C124.736 162.561 124.768 162.849 124.704 163.105C124.64 163.329 124.512 163.553 124.384 163.745C123.872 164.385 123.2 164.865 122.432 165.121"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M125.024 156.544C126.624 157.6 131.264 161.472 132.128 163.072C132.48 163.68 132 164.416 131.424 164.704C130.88 164.96 130.176 164.992 129.6 164.8"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M116.672 165.312C115.84 166.432 114.144 167.169 112.768 167.265C110.944 167.425 108.928 166.849 107.104 166.785C104.704 166.689 98.7841 166.752 98.7841 166.752"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.2 113.408C59.776 114.848 52.352 119.68 53.76 131.2C55.168 142.464 58.144 154.016 59.328 158.048C60.832 163.168 63.584 167.2 71.392 167.2C76.672 167.2 96.736 167.2 96.736 167.2C96.736 167.2 94.976 157.216 99.072 153.184C98.816 150.752 95.84 150.56 88.864 150.176C82.848 149.824 77.952 149.152 77.952 149.152L74.368 132.448C74.368 132.448 71.744 119.456 67.2 113.408Z"
      fill="#F55571"
    />
    <path
      d="M67.712 151.712C70.624 149.504 73.376 148.928 77.952 149.152"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.76 131.2C55.168 142.464 58.144 154.016 59.328 158.048C60.832 163.168 63.584 167.2 71.392 167.2C76.672 167.2 96.736 167.2 96.736 167.2C96.736 167.2 94.816 157.568 99.072 153.184C98.816 150.752 96 150.784 88.864 150.176C82.848 149.664 77.952 149.152 77.952 149.152L74.368 132.448"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96.7361 167.584H101.024C101.024 167.584 99.7121 157.312 103.2 153.728L99.0721 153.184C98.0161 154.88 94.9441 159.616 96.7361 167.584Z"
      fill="#303030"
    />
    <path
      d="M255.904 167.584H77.056C76.48 167.584 76.032 168.032 76.032 168.608V171.2C76.032 171.776 76.48 172.224 77.056 172.224H255.904C256.448 172.224 256.928 171.776 256.928 171.2V168.608C256.928 168.032 256.448 167.584 255.904 167.584Z"
      fill="#FF95FB"
    />
    <path d="M256.928 174.304H76.032V172.224H256.928V174.304Z" fill="#303030" />
    <path d="M170.048 275.552H162.624V173.184H170.048V275.552Z" fill="#303030" />
    <path
      d="M203.136 279.552H129.536C133.344 274.848 139.072 272.096 145.12 272.096H187.552C193.6 272.096 199.328 274.848 203.136 279.552Z"
      fill="#303030"
    />
    <path
      d="M144.544 44.0962C130.08 44.0962 118.368 52.8002 118.368 63.5522C118.368 68.2242 120.608 72.5442 124.32 75.9042C123.616 78.2402 121.088 79.9362 119.104 80.2882C118.08 80.4482 118.976 81.6642 122.336 82.0162C125.312 82.3362 128.096 81.6322 130.144 79.7762C134.272 81.8242 139.232 83.0082 144.544 83.0082C159.008 83.0082 170.72 74.3042 170.72 63.5522C170.72 52.8002 159.008 44.0962 144.544 44.0962Z"
      fill="white"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M154.784 64.3203C154.784 66.2723 156.352 67.8723 158.304 67.8723C160.256 67.8723 161.824 66.2723 161.824 64.3203C161.824 62.4003 160.256 60.8003 158.304 60.8003C156.352 60.8003 154.784 62.4003 154.784 64.3203ZM140.8 64.3203C140.8 66.2723 142.368 67.8723 144.32 67.8723C146.272 67.8723 147.84 66.2723 147.84 64.3203C147.84 62.4003 146.272 60.8003 144.32 60.8003C142.368 60.8003 140.8 62.4003 140.8 64.3203ZM126.816 64.3203C126.816 66.2723 128.384 67.8723 130.336 67.8723C132.288 67.8723 133.856 66.2723 133.856 64.3203C133.856 62.4003 132.288 60.8003 130.336 60.8003C128.384 60.8003 126.816 62.4003 126.816 64.3203Z"
      fill="#00826F"
    />
    <path
      d="M225.216 201.152C231.808 207.936 234.144 214.688 234.848 227.52C235.584 240.384 237.056 254.049 239.52 259.648L225.696 260.992C225.696 260.992 220.544 247.616 214.976 234.816C209.44 221.984 205.28 213.248 204.256 208.416C203.2 203.584 201.408 193.888 209.888 188.544C215.584 184.96 223.456 183.104 223.456 183.104L225.216 201.152Z"
      fill="#FFC4B0"
    />
    <path
      d="M239.136 249.345C236.192 246.721 231.264 249.377 228.576 252.929C226.752 255.361 225.6 256.737 223.648 257.569L221.92 253.825C219.968 254.497 219.872 256.097 218.656 258.177C216.416 262.113 213.952 264.673 206.688 267.073C200.672 269.057 200.064 270.977 200.096 273.601C200.096 273.601 205.184 274.913 215.168 274.241C221.216 273.793 228.224 270.529 232.096 267.937C237.76 264.161 244.992 263.329 245.76 266.337C244.768 262.305 243.296 260.033 242.368 257.089C241.056 253.121 240.768 250.817 239.136 249.345Z"
      fill="#00826F"
    />
    <path
      d="M213.152 279.136C222.112 279.136 220.416 279.136 225.408 279.136C230.4 279.136 234.4 277.184 238.688 275.104C245.696 271.68 246.368 270.144 245.76 269.376C243.392 267.936 239.872 268.288 232.608 271.968C225.344 275.648 221.408 278.592 213.152 279.136Z"
      fill="#303030"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M245.536 265.857C244.384 263.329 237.76 264.161 232.096 267.937C228.224 270.529 221.216 273.793 215.168 274.241C205.184 274.913 200.096 273.601 200.096 273.601C199.488 273.601 199.52 274.081 199.52 275.489C199.52 276.929 199.52 278.369 201.824 278.753C203.68 279.073 209.76 279.297 215.232 278.977C220.928 278.657 225.536 277.377 229.76 274.913C235.424 271.617 237.504 270.465 239.936 269.665C242.88 268.673 245.248 269.057 245.76 269.377C246.304 269.665 246.752 268.449 245.536 265.857Z"
      fill="white"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M234.56 159.104C234.56 159.104 231.232 168.96 229.984 175.776C229.984 175.776 218.528 177.056 205.216 182.464C191.936 187.84 189.184 190.784 187.84 196.48C186.432 202.528 187.84 210.976 194.016 225.056C199.552 237.632 205.28 250.624 207.488 255.296C210.752 262.208 212.608 266.432 212.608 266.432L224.704 258.112C224.704 258.112 223.552 251.232 222.592 242.752C219.84 218.784 219.712 212.288 212.32 205.312C212.32 205.312 251.712 205.312 260.64 205.312C272.128 205.312 276.8 201.056 279.552 194.752C282.304 188.448 280.224 168.864 270.016 155.744C270.016 155.744 252.384 154.144 234.56 159.104Z"
      fill="#FFC4B0"
    />
    <path
      d="M224.704 258.112C224.704 258.112 223.552 251.232 222.592 242.752C219.84 218.784 219.712 212.288 212.32 205.312"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M224 249.28C221.056 246.656 216.128 249.312 213.472 252.864C211.616 255.296 210.496 256.672 208.544 257.504L206.784 253.76C204.832 254.432 204.736 256.032 203.552 258.112C201.28 262.048 198.816 264.608 191.584 267.008C185.568 268.992 184.928 270.912 184.96 273.536C184.96 273.536 190.048 274.848 200.032 274.176C206.112 273.728 213.088 270.464 216.96 267.872C222.656 264.096 229.856 263.264 230.624 266.272C229.632 262.24 228.192 259.968 227.232 257.024C225.952 253.056 225.632 250.752 224 249.28Z"
      fill="#00826F"
    />
    <path
      d="M230.624 266.272C229.632 262.24 228.192 259.968 227.232 257.024C225.952 253.056 225.632 250.752 224 249.28C223.637 248.96 223.221 248.704 222.752 248.512"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M198.016 279.072C206.976 279.072 205.312 279.072 210.304 279.072C215.264 279.072 219.264 277.12 223.552 275.04C230.56 271.616 231.264 270.08 230.656 269.312C228.256 267.872 224.736 268.224 217.472 271.904C210.208 275.552 206.272 278.528 198.016 279.072Z"
      fill="#303030"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M230.4 265.792C229.248 263.264 222.656 264.096 216.96 267.872C213.088 270.464 206.112 273.728 200.032 274.176C190.048 274.848 184.96 273.536 184.96 273.536C184.384 273.536 184.384 274.016 184.384 275.424C184.384 276.864 184.384 278.304 186.72 278.688C188.544 279.008 194.656 279.232 200.096 278.912C205.792 278.592 210.4 277.312 214.624 274.848C220.288 271.552 222.368 270.4 224.832 269.6C227.744 268.608 230.112 268.992 230.656 269.312C231.168 269.6 231.616 268.384 230.4 265.792Z"
      fill="white"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M234.464 156.544C232.736 161.248 230.112 168.608 229.312 174.752C214.816 176.672 200.768 183.488 200.768 183.488C200.768 183.488 199.136 193.152 202.272 198.272C206.08 204.512 211.84 206.784 211.84 206.784C211.84 206.784 250.752 206.784 256.768 206.784C262.816 206.784 271.136 206.912 277.472 201.088C283.84 195.264 282.24 183.2 280.672 176.64C279.104 170.112 274.592 159.552 269.856 153.728C261.408 149.984 241.376 152.352 234.464 156.544Z"
      fill="#F55571"
    />
    <path
      d="M232.64 161.664C239.936 157.696 264.544 155.456 272.64 157.728"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M263.744 156.832C269.056 166.176 274.656 177.728 271.328 191.488C268.512 203.104 257.696 206.784 245.312 206.784"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M240.128 154.24C237.568 161.312 238.24 170.336 238.496 172.832C235.968 173.344 236.224 175.584 236.288 176.352C234.016 175.744 232.992 177.952 233.344 179.328C233.344 179.328 218.976 181.696 200.448 189.6"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M229.024 174.784C232.864 174.528 236.8 176 239.552 178.688"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M266.4 171.52L247.616 171.904C247.808 179.712 247.072 187.424 247.072 187.424C247.072 187.424 252.64 191.68 257.12 194.656C264.736 192 267.776 188.16 267.776 188.16C267.776 188.16 269.056 179.84 266.4 171.52Z"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M279.104 171.52C275.712 171.52 274.656 171.52 274.656 171.52C274.656 171.52 277.152 179.328 276 188.512C277.184 190.144 279.968 192.192 281.408 193.088"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M201.792 137.184C205.504 139.328 217.344 146.112 225.696 146.944C232.608 147.648 237.856 146.4 239.616 134.048L223.264 130.688C223.264 130.688 218.496 130.656 213.792 130.272C209.248 129.888 208.192 126.304 204.608 120.576C202.592 117.376 199.776 115.936 198.624 115.904C197.472 115.872 197.056 117.504 198.176 119.072C199.296 120.672 200.896 123.136 199.072 123.2C197.248 123.232 192.8 121.536 190.656 120.768C188.064 119.84 187.52 119.84 186.656 120.192C184.96 120.864 185.44 121.824 185.44 121.824C185.44 121.824 184 120.832 183.2 121.952C182.4 123.104 183.872 124.224 183.872 124.224C183.872 124.224 183.328 126.368 184.704 127.712C186.528 129.504 188.16 130.784 190.88 131.968C193.6 133.152 198.08 135.008 201.792 137.184Z"
      fill="#FFC4B0"
    />
    <path
      d="M191.072 130.304C190.4 129.76 189.984 129.184 188 126.848C187.776 126.56 187.552 126.24 187.616 125.888C187.68 125.6 187.936 125.408 188.224 125.312C188.992 125.024 189.856 125.408 190.528 125.888C192.768 127.456 194.688 130.432 197.792 129.44C198.528 129.216 199.2 128.864 199.904 128.672C201.952 128.16 204.288 129.024 205.504 130.784"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M183.872 124.224C184.736 123.968 185.568 124.608 187.712 125.696"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M185.44 121.824C186.72 122.336 192.736 124.832 192.736 124.832"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M234.432 106.688C236.608 106.4 242.72 104.928 243.84 92.5122C244.928 80.4482 232.224 72.6082 221.536 76.4162C220.096 76.1922 217.472 75.4882 213.28 77.3762C206.976 80.2562 204.896 88.9602 210.656 93.4082C217.472 98.6882 225.216 102.56 232.928 106.4C233.44 106.688 233.952 106.752 234.432 106.688Z"
      fill="#303030"
    />
    <path
      d="M211.008 96.384C211.008 96.384 211.456 94.272 211.648 92.192C211.744 90.976 211.712 89.76 212.064 87.584C212.512 84.896 214.592 81.568 218.24 80.896C215.424 92.96 226.784 92.576 226.784 92.576L229.312 99.392C230.24 101.568 231.264 105.728 236.448 106.112L239.488 113.024C236.928 120.288 226.432 119.584 223.36 116.896L226.272 115.008L224.16 107.68C220.288 109.088 216.096 109.856 213.536 108.48C212.192 107.776 211.584 106.272 211.648 104.608C210.848 104.032 210.464 103.296 210.912 101.984C210.976 101.856 211.008 101.728 211.008 101.6C211.008 101.408 210.912 101.248 210.816 101.088C210.368 100.288 210.464 99.712 210.624 98.88C210.752 98.048 210.848 97.216 211.008 96.384Z"
      fill="#FFC4B0"
    />
    <path
      d="M226.784 92.5761C227.648 91.3921 229.408 90.5281 231.488 91.2001C233.376 91.8081 234.464 93.6641 234.272 95.5201C234.08 97.3441 232.736 98.6881 231.168 99.2641C229.28 99.9681 227.776 99.3601 226.656 98.5601"
      fill="#FFC4B0"
    />
    <path
      d="M226.784 92.5761C227.648 91.3921 229.408 90.5281 231.488 91.2001C233.376 91.8081 234.464 93.6641 234.272 95.5201C234.08 97.3441 232.736 98.6881 231.168 99.2641C229.28 99.9681 227.776 99.3601 226.656 98.5601"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M231.52 95.2002C231.2 94.3362 230.496 93.8242 229.728 93.8882C228.928 93.9842 228.096 94.6242 227.584 95.5842"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M229.856 95.84C229.728 95.456 229.504 95.168 229.216 94.976C228.928 94.784 228.608 94.72 228.288 94.688"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M218.976 92.672C218.976 92.672 218.176 91.808 217.088 91.296C216.256 90.88 215.072 90.944 214.624 91.264C214.4 91.552 214.272 92.032 214.56 93.088C215.36 94.048 217.312 93.632 218.976 92.672Z"
      fill="white"
    />
    <path
      d="M214.656 93.4401C215.296 93.8881 216.128 93.7921 216.544 93.1841C216.992 92.5761 216.832 91.6801 216.224 91.2321C215.584 90.7521 214.752 90.8801 214.304 91.4881C213.888 92.0961 214.048 92.9601 214.656 93.4401Z"
      fill="#303030"
    />
    <path d="M216.576 91.3599L215.424 92.3199L216.864 92.0639L216.576 91.3599Z" fill="white" />
    <path
      d="M214.624 91.2637C216.16 90.7517 217.696 91.3277 218.976 92.6717"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M213.408 90.8159C213.792 91.3279 214.368 91.2959 215.072 91.1999"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M210.944 98.7201C209.44 98.4641 208.256 97.1201 209.28 95.8401C210.144 94.7521 211.648 92.1921 211.904 90.9761"
      fill="#FFC4B0"
    />
    <path
      d="M210.944 98.7201C209.44 98.4641 208.256 97.1201 209.28 95.8401C210.144 94.7521 211.648 92.1921 211.904 90.9761"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M212.992 101.088C212.64 100.928 212.288 100.8 211.904 100.8C212 100.832 212.064 100.928 212.096 101.024C212.128 101.12 212.16 101.216 212.128 101.312C212.128 101.632 212.096 101.942 212.032 102.24C212 102.336 212 102.464 212.064 102.56C212.107 102.582 212.149 102.592 212.192 102.592C212.544 102.688 212.928 102.656 213.28 102.56C213.632 102.432 214.016 102.272 214.24 101.952C214.283 101.888 214.304 101.824 214.304 101.76C214.336 101.504 213.888 101.44 213.728 101.376C213.504 101.312 213.312 101.216 213.088 101.12C213.045 101.099 213.013 101.088 212.992 101.088Z"
      fill="#303030"
    />
    <path
      d="M212.8 101.76C212.992 101.504 213.184 101.376 213.504 101.344C213.728 101.344 214.048 101.376 214.176 101.568C214.304 101.76 214.208 101.984 214.016 102.112C213.728 102.336 213.248 102.624 212.832 102.592C212.384 102.56 212.544 102.08 212.8 101.76Z"
      fill="#FF95FB"
    />
    <path
      d="M211.296 100.64C212.352 100.64 212.576 100.928 213.184 101.184C213.696 101.376 214.368 101.632 215.072 101.248"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M211.808 100.768C212.032 100.96 212.16 101.248 212.16 101.536C212.192 101.888 212.064 102.144 212 102.368C211.968 102.432 211.968 102.496 212.064 102.592C212.096 102.624 212.192 102.656 212.288 102.688C212.704 102.752 213.984 102.656 214.624 101.472"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M213.376 103.936C212.896 104.096 212.384 104.064 211.904 103.904"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M214.656 86.912C217.056 86.688 218.88 87.264 219.936 88.928"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M226.912 106.112C226.56 107.232 225.312 108.128 224.48 108.8L224.064 107.712C224.96 107.424 226.432 106.656 226.912 106.112Z"
      fill="#303030"
    />
    <path
      d="M254.24 112.256C257.024 113.856 259.968 114.88 263.456 124.096C266.592 132.352 274.272 151.872 270.72 154.848C270.72 154.848 265.504 153.024 253.312 153.728C243.072 154.304 236.16 156.832 233.824 158.336C230.816 157.44 234.368 152.128 228.768 150.144C219.04 146.688 219.008 128.64 221.088 112.544C229.088 110.752 245.728 109.12 254.24 112.256Z"
      fill="white"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M251.168 104.128C250.976 100.384 247.904 97.4082 244.128 97.4082C240.32 97.4082 237.248 100.416 237.088 104.192C234.912 105.152 233.408 107.328 233.408 109.856C233.408 113.056 235.84 115.712 238.944 116.032C239.392 121.76 244.16 126.304 250.016 126.304C256.16 126.304 261.12 121.312 261.12 115.168C261.12 109.44 256.768 104.704 251.168 104.128Z"
      fill="#303030"
    />
    <path
      d="M242.944 98.7524C243.872 99.3924 245.216 99.3923 246.112 98.6883C246.432 98.4323 246.72 98.0484 246.72 97.6644C246.72 97.4404 246.656 97.2483 246.56 97.0563C245.632 95.2323 243.136 96.3204 242.272 97.6644C241.952 96.6724 241.056 95.8724 240 95.6804C239.616 95.5844 239.2 95.6163 238.88 95.8083C238.528 95.9683 238.272 96.3843 238.336 96.7683C238.4 97.0883 238.688 97.3763 238.976 97.5683C239.744 98.0483 240.64 98.3044 241.536 98.2404C241.312 98.3364 241.152 98.4963 240.992 98.6883C240.64 99.1043 240.32 99.5204 240.16 100.032C240.032 100.544 240.128 101.12 240.512 101.472C241.184 102.112 242.176 101.44 242.656 100.896C243.168 100.384 243.136 99.0083 242.624 98.4963C242.731 98.603 242.837 98.6884 242.944 98.7524Z"
      stroke="#F55571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M241.952 97.9521C243.808 98.9761 245.088 100.96 245.28 103.072"
      stroke="#F55571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M241.92 106.752C242.176 103.936 242.528 101.728 241.952 97.9521"
      stroke="#F55571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M225.504 112.256C218.496 113.408 216.64 118.08 213.12 129.888C209.888 140.704 208.128 147.456 206.496 155.136C204.512 164.352 208 167.296 212.544 167.52C216.768 167.744 224.032 167.584 224.032 167.584C226.24 167.584 227.744 165.152 227.072 163.04C226.496 161.216 225.44 159.232 223.52 158.016L229.792 134.496L225.504 112.256Z"
      fill="#FFC4B0"
    />
    <path
      d="M221.728 163.168C222.912 161.12 223.296 160.096 223.52 158.016"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M227.68 111.36C221.312 111.808 217.28 112.544 214.08 122.592C211.872 129.504 208.896 140.96 208.896 140.96C208.896 140.96 211.808 143.872 218.976 145.664C225.216 147.232 227.584 145.664 227.584 145.664L231.232 132.256"
      fill="white"
    />
    <path
      d="M227.68 111.36C221.312 111.808 217.28 112.544 214.08 122.592C211.872 129.504 208.896 140.96 208.896 140.96C208.896 140.96 211.808 143.872 218.976 145.664C225.216 147.232 227.584 145.664 227.584 145.664L231.232 132.256"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M298.336 206.784C299.808 206.784 300.384 208.512 299.168 209.248C295.712 211.456 291.552 212.64 287.264 212.64H217.792C213.504 212.64 209.344 211.456 205.888 209.248C204.672 208.512 205.28 206.784 206.72 206.784H298.336Z"
      fill="#FF95FB"
    />
    <path
      d="M276.608 165.6C268.16 165.6 261.76 164.8 257.184 163.904C251.712 162.816 247.392 158.624 246.08 153.216C245.024 148.832 244.064 143.68 244.352 140.352C244.896 134.112 249.792 130.72 255.936 131.904C262.016 133.056 269.824 134.24 276.608 134.24C283.392 134.24 291.2 133.056 297.28 131.904C303.424 130.72 308.32 134.112 308.864 140.352C309.152 143.68 308.192 148.832 307.136 153.216C305.824 158.624 301.504 162.816 296 163.904C291.456 164.8 285.056 165.6 276.608 165.6Z"
      fill="#FF95FB"
    />
    <path
      d="M247.52 278.912L253.12 223.776C253.792 217.44 258.624 212.64 264.384 212.64H281.856C287.616 212.64 292.448 217.44 293.088 223.776L298.72 278.912"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M297.248 264.512H248.992" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M206.592 278.912L212.192 223.776C212.832 217.44 217.664 212.64 223.424 212.64H240.928C246.688 212.64 251.52 217.44 252.16 223.776L257.792 278.912"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M256.32 264.512H208.064" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M294.784 145.888C294.784 153.664 292.352 185.92 291.04 202.56C290.624 208.256 285.856 212.64 280.16 212.64H275.52"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M259.872 145.888C259.872 153.664 257.44 185.92 256.16 202.56C255.712 208.256 250.976 212.64 245.28 212.64H240.608"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M174.88 67.104C188.608 67.104 199.744 75.392 199.744 85.6C199.744 90.048 197.632 94.112 194.112 97.312C194.784 99.552 197.184 101.152 199.072 101.472C200.032 101.632 199.168 102.784 195.968 103.136C193.152 103.424 190.496 102.752 188.544 101.024C184.64 102.944 179.936 104.064 174.88 104.064C161.152 104.064 150.016 95.776 150.016 85.6C150.016 75.392 161.152 67.104 174.88 67.104Z"
      fill="white"
      stroke="#303030"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M161.824 82.9761C159.968 82.9761 158.464 84.4801 158.464 86.3361C158.464 88.1601 159.968 89.6641 161.824 89.6641C163.648 89.6641 165.152 88.1601 165.152 86.3361C165.152 84.4801 163.648 82.9761 161.824 82.9761ZM175.104 82.9761C173.248 82.9761 171.744 84.4801 171.744 86.3361C171.744 88.1601 173.248 89.6641 175.104 89.6641C176.928 89.6641 178.432 88.1601 178.432 86.3361C178.432 84.4801 176.928 82.9761 175.104 82.9761ZM188.384 82.9761C186.528 82.9761 185.024 84.4801 185.024 86.3361C185.024 88.1601 186.528 89.6641 188.384 89.6641C190.24 89.6641 191.712 88.1601 191.712 86.3361C191.712 84.4801 190.24 82.9761 188.384 82.9761Z"
      fill="#FF95FB"
    />
  </svg>
);
